import { library } from '@fortawesome/fontawesome-svg-core/index.js';
import svgConverter from '@fortawesome/fontawesome-pro/svg-converter';

export default async () => {
  const icons = await svgConverter([
    'angle-down',
    'arrow-left',
    'arrows-rotate',
    'arrow-rotate-left',
    'arrow-rotate-right',
    'arrow-up',
    'arrow-up-right-from-square',
    'ban',
    'bars',
    'bars-staggered',
    'brackets-curly',
    'box-archive',
    'box-open',
    'chart-mixed',
    'check',
    'chevron-down',
    'chevron-up',
    'circle-info',
    'circle-plus',
    'circle-dollar-to-slot',
    'clock-rotate-left',
    'clone',
    'compress',
    'code',
    'copy',
    'credit-card',
    'download',
    'ellipsis',
    'ellipsis-vertical',
    'envelope',
    'envelope-open',
    'expand',
    'eye',
    'eye-slash',
    'face-thinking',
    'file-arrow-up',
    'file-check',
    'flask',
    'floppy-disk',
    'folder-plus',
    'gear',
    'house',
    'indent',
    'key-skeleton-left-right',
    'link-slash',
    'list-dropdown',
    'lock',
    'magnifying-glass',
    'page',
    'pencil',
    'pen-field',
    'pen-to-square',
    'play',
    'plus',
    'right-from-bracket',
    'rotate',
    'rotate-left',
    'rotate-right',
    'share-from-square',
    'thumbtack',
    'trash-can',
    'trash-can-clock',
    'unlock',
    'upload',
    'user',
    'users',
    'xmark',
    'gears',
    'key',
    'clipboard-list',
    'user-xmark',
  ], 'fal');

  library.add(...icons);
};
