var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"notification","enter-class":_vm.$style.notification_enter,"enter-active-class":_vm.$style.notification_enter_active,"leave-to-class":_vm.$style.notification_leave,"leave-active-class":_vm.$style.notification_leave_active},on:{"after-leave":_vm.afterLeave}},[(_vm.show)?_c('div',{class:[
      'message is-danger',
      _vm.$style.wrapper,
    ],on:{"mouseover":_vm.clearTimer,"mouseout":() => _vm.startTimer(_vm.duration - 500)}},[_c('div',{staticClass:"message-body"},[_c('div',{class:_vm.$style.inner},[_c('div',{class:_vm.$style.picture},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'face-thinking']}})],1),_c('div',[_c('p',{class:_vm.$style.text},[_vm._v(" An unexpected error has occurred. Contact "),_c('a',{attrs:{"href":"mailto: support@plumsail.com"}},[_vm._v("support@plumsail.com")]),_vm._v(" for assistance. ")]),_c('p',{class:_vm.$style.text},[_vm._v(" Error: "+_vm._s(_vm.message)+" ")])])]),_c('button',{class:[
          'button is-ghost modal-close-btn is-shadowless has-text-danger is-small',
          _vm.$style.close,
        ],attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'xmark']}})],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }