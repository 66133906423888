<template>
  <div
    v-if="item"
    :class="$style.product"
  >
    <div :class="[$style.content, $style[`content--${size}`]]">
      <div :class="$style['icon-wrapper']">
        <img
          :src="item.icon"
          :alt="name"
          :style="item.size[size]"
          :class="$style.icon"
        >
      </div>

      <div :class="$style.text">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
import documentsIcon from '@/assets/documents-icon-color.svg';
import formsIcon from '@/assets/forms-logo.svg';
import actionsIcon from '@/assets/actions-icon-color.svg';
import plumsailIcon from '@/assets/logo.svg';

export default {
  name: 'Product',
  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'normal',
    },
  },
  computed: {
    item() {
      const mapping = {
        documents: {
          icon: documentsIcon,
          size: {
            normal: {
              width: '22px',
              height: '22px',
            },
            medium: {
              width: '24px',
              height: '24px',
            },
          },
        },
        forms: {
          icon: formsIcon,
          size: {
            normal: {
              width: '20px',
              height: '20px',
            },
            medium: {
              width: '22px',
              height: '22px',
            },
          },
        },
        actions: {
          icon: actionsIcon,
          size: {
            normal: {
              width: '21px',
              height: '21px',
            },
            medium: {
              width: '24px',
              height: '24px',
            },
          },
        },
        plumsail: {
          icon: plumsailIcon,
          size: {
            normal: {
              width: '18px',
              height: '20px',
            },
            medium: {
              width: '22px',
              height: '26px',
            },
          },
        },
      };

      return mapping[this.name];
    },
  },
};
</script>

<style lang="scss" module>
.product {
  .content {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .icon-wrapper {
      max-width: 26px;
      min-width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      font-size: 1rem;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    &--medium {
      font-size: 16px;
    }
  }
}
</style>
