import { library } from '@fortawesome/fontawesome-svg-core';
import svgConverter from '@fortawesome/fontawesome-pro/svg-converter';

export default async () => {
  const faBracketsCurly = await svgConverter([
    'brackets-curly',
    'file-pdf',
    'file-word',
    'file-excel',
    'file-powerpoint',
    'file-code',
    'thumbtack',
  ], 'fas');

  library.add(
    faBracketsCurly,
  );
};
