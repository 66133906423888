import Vuelidate from 'vuelidate';
import VTooltip from 'v-tooltip';
import VModal from 'vue-js-modal';
import VueFriendlyIframe from 'vue-friendly-iframe';
import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import ClickOutside from 'vue-click-outside';
import axios from 'axios';
import VueAxios from 'vue-axios';
import NProgress from 'vue-nprogress';
import VueGtm from '@gtm-support/vue2-gtm';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  ConfigProgrammatic,
  Dialog,
  Modal,
  Button,
  Dropdown,
  Field,
  Input,
  Switch,
  Table,
  Upload,
  Skeleton,
  Checkbox,
  Sidebar,
  Tooltip,
  Slider,
} from 'buefy';
import App from '@/app.vue';
import useFormsHeader from '@/stores/forms/header';
import router from '@/router/index.js';
import authGuard from '@/api/guard.js';
import authCatcher from '@/api/catcher.js';
import { apiEndpoint, formsApiEndpoint } from '@/utils/url-manager.js';
import '@/plugins/FontAwesome/index.js';
import 'vue-class-component/hooks.js';
import directives from './directives/index.ts';
import {
  useSettingsStore,
  useAppStore,
  useTeamsStore,
  plugin,
} from './stores/index.js';

plugin();
Vue.router = router;

Vue.use(VueGtm, {
  id: 'GTM-P5S72R2',
  vueRouter: router,
});

axios.defaults.baseURL = apiEndpoint;
axios.defaults.baseFormsUrl = formsApiEndpoint;

Vue.use(VueAxios, axios);

Vue.use(NProgress);
Vue.use(Vuelidate);
Vue.use(VTooltip);
Vue.use(VModal, {
  dialog: true, dynamic: true, dynamicDefaults: { clickToClose: false, draggable: true },
});
Vue.use(VueFriendlyIframe);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

[
  Dialog,
  Modal,
  Button,
  Dropdown,
  Field,
  Input,
  Switch,
  Table,
  Upload,
  Skeleton,
  Checkbox,
  Sidebar,
  Tooltip,
  Slider,
].forEach((component) => Vue.use(component));

ConfigProgrammatic.setOptions({
  defaultIconComponent: 'FontAwesomeIcon',
  defaultIconPack: 'fal',
  defaultStatusIcon: false,
});

// Enable devtools
Vue.config.devtools = true;

// Allow <zapier-app-directory> custom element
Vue.config.ignoredElements = ['zapier-app-directory'];

const nprogress = new NProgress({ parent: '.nprogress-container' });

// Guard and axios catcher for auth
router.beforeEach(authGuard(useSettingsStore, pinia));
authCatcher({
  settingsStore: useSettingsStore,
  teamsStore: useTeamsStore,
  pinia,
})();

router.afterEach((to) => {
  const formsHeader = useFormsHeader(pinia);
  formsHeader.pageTitle = null;
  formsHeader.clearPageBreadcrumbs();

  const appStore = useAppStore(pinia);
  appStore.setRoute(to);
});

Vue.directive('click-outside', ClickOutside);

Vue.directive('click-outside', ClickOutside);

new Vue({
  ...App,
  router,
  nprogress,
  directives,
  pinia,
}).$mount('#app');
