/* eslint-disable global-require */
export default [
  {
    name: 'AccountSettings',
    path: '/account',
    meta: {
      title: 'Account',
      sidebarName: 'Account',
      sidebarIcon: 'user',
    },
    component: () => import('@/views/settings/account-settings.vue'),
  },
  {
    name: 'TeamsSettings',
    path: '/teams',
    meta: {
      title: 'Teams',
      sidebarName: 'Teams',
      sidebarIcon: 'users',
    },
    component: () => import('@/views/settings/team-settings.vue'),
  },
  {
    name: 'AuditLog',
    path: '/account/audit-log',
    meta: {
      title: 'Audit',
      sidebarName: 'Audit',
      sidebarIcon: 'users',
    },
    component: () => import('@/views/settings/audit-log.vue'),
  },
];
