import { defineStore } from 'pinia';
import axios from 'axios';
import { apiEndpoint } from '@/utils/url-manager.js';

const endpoint = apiEndpoint;

export default defineStore('apiKeys', {
  state: () => ({
    selectedKey: null,
    apiKeys: [],
    product: null,
  }),

  actions: {
    setProduct(value) { this.product = value; },

    async consentRequest({ domain, apiKey }) {
      try {
        const { data } = await axios.post(`${endpoint}actions/state?domain=${domain}&source=${window.location.href}`, apiKey, { showProgressBar: false });
        const authority = domain.replace('sharepoint.com', 'onmicrosoft.com');
        const oauth = await this.loadOauthSettings();
        const query = new URLSearchParams(
          {
            client_id: oauth.data.clientId,
            state: data.state,
            redirect_uri: `${endpoint}actions/consent`,
            prompt: 'admin_consent',
          },
        ).toString();

        window.location = `https://login.microsoftonline.com/${authority}/adminconsent?${query}`;

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async loadOauthSettings() {
      try {
        const { data } = await axios.get(`${endpoint}actions/oauth/admin`);
        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async getKeys() {
      try {
        const { data } = await axios.get(`${endpoint}${this.product}/apikeys`);
        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async createKey({ code, apiKey }) {
      try {
        const { data } = await axios.post(`${endpoint}${this.product}/apikeys${code ? `?code=${code}` : ''}`, apiKey);
        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async editKey({ apiKey }) {
      try {
        const { data } = await axios.patch(`${endpoint}${this.product}/apikeys`, apiKey);
        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async removeKey({ id }) {
      try {
        const { data } = await axios.delete(`${endpoint}${this.product}/apikeys/${id}`);
        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },
  },
});
