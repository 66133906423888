import Vue from 'vue';
import Router from 'vue-router';
import formsRoutes from '@/router/forms.js';
import documentsRoutes from '@/router/documents.js';
import actionsRoutes from '@/router/actions.js';
import settingsRoutes from '@/router/settings.js';

Vue.use(Router);

export default new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      name: 'Home',
      path: '/',
      meta: {
        sidebarHidden: true,
      },
      component: () => import('@/views/home.vue'),
    },
    ...settingsRoutes,
    formsRoutes,
    documentsRoutes,
    actionsRoutes,
    {
      path: '*',
      redirect: '/',
    },
  ],
});
