const themesList = [
  {
    name: 'plumsail',
    url: `/base.${process.env.VUE_APP_Timestamp}.css`,
  },
  {
    name: 'actions',
    url: `/actions.${process.env.VUE_APP_Timestamp}.css`,
  },
  {
    name: 'documents',
    url: `/documents.${process.env.VUE_APP_Timestamp}.css`,
  },
  {
    name: 'forms',
    url: `/forms.${process.env.VUE_APP_Timestamp}.css`,
  },
];

let themes = null;
let lastTheme = null;

const preloadTheme = ({ url }) => {
  const link = document.createElement('link');

  link.rel = 'stylesheet';
  link.href = url;
  document.head.appendChild(link);

  return new Promise((resolve, reject) => {
    link.onload = (e) => {
      const { sheet } = e.target;
      sheet.disabled = true;
      resolve(sheet);
    };

    link.onerror = reject;
  });
};

export const setTheme = ({ name = 'base' }) => {
  lastTheme = name;

  if (!themes) return;

  themes.forEach((t) => {
    const theme = t.sheet;

    theme.disabled = true;

    if (name === t.name) {
      theme.disabled = false;
    }
  });
};

export const initThemes = async () => {
  const data = await Promise.all(themesList.map(({ url }) => preloadTheme({ url })));

  themes = themesList.map((t, i) => ({ ...t, sheet: data[i] }));

  setTheme({ name: lastTheme });

  return themes;
};
