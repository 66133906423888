export default [
  {
    id: 0,
    route: 'default',
    data: [
      {
        name: 'Videos',
        list: [
          {
            title: 'Getting started with processes',
            link: 'https://www.youtube.com/watch?v=tw7eYeYkRQA',
            imageLink: 'https://img.youtube.com/vi/tw7eYeYkRQA/mqdefault.jpg',
            type: 'video',
          },
        ],
      },
      {
        name: 'Articles',
        list: [
          {
            title: 'Create a new process',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/create-process.html',
          },
          {
            title: 'Prepare and test a template',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/edit-test-template.html',
          },
          {
            title: 'Configure output file and other settings',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/configure-settings.html',
          },
          {
            title: 'Deliver generated documents',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/create-delivery.html',
          },
          {
            title: 'Start process',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/start-process.html',
          },
          {
            title: 'Runs history',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/runs-history.html',
          },
          {
            title: 'Processes examples',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/process-examples.html',
          },
        ],
      },
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
  {
    id: 1,
    route: 'DocumentsAPIKeys',
    data: [
      {
        name: 'Videos',
        list: [
          {
            title: 'Setting up Power Automate',
            link: 'https://www.youtube.com/watch?v=la7IYw99JBk',
            imageLink: 'https://img.youtube.com/vi/la7IYw99JBk/mqdefault.jpg',
            type: 'video',
          },
        ],
      },
      {
        name: 'Articles',
        list: [
          {
            title: 'Manage API keys',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/api-keys.html',
          },
          {
            title: 'Use REST API',
            link: 'https://plumsail.com/docs/documents/v1.x/getting-started/use-as-rest-api.html',
          },
          {
            title: 'Use Power Automate',
            link: 'https://plumsail.com/docs/documents/v1.x/getting-started/use-from-flow.html',
          },
        ],
      },
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    route: 'DocumentsReports',
    data: [
      {
        name: 'Articles',
        list: [
          {
            title: 'Analyze documents statistics',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/reports.html',
          },
        ],
      },
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    route: 'DocumentsSubscriptions',
    data: [
      {
        name: 'Articles',
        list: [
          {
            title: 'Manage subscription',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/manage-email-notifications.html',
          },
          {
            title: 'Manage account',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/account-settings.html',
          },
        ],
      },
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    route: 'DocumentsProcessEdit',
    templateType: 'DOCX',
    data: [
      {
        name: 'Videos',
        list: [
          {
            title: 'Getting started with processes',
            link: 'https://www.youtube.com/watch?v=tw7eYeYkRQA',
            imageLink: 'https://img.youtube.com/vi/tw7eYeYkRQA/mqdefault.jpg',
            type: 'video',
          },
        ],
      },
      {
        name: 'Articles',
        list: [
          {
            title: 'DOCX template syntax',
            link: 'https://plumsail.com/docs/documents/v1.x/document-generation/docx/index.html',
          },
          {
            title: 'Prepare and test a template',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/edit-test-template.html',
          },
          {
            title: 'Configure output file and other settings',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/configure-settings.html',
          },
          {
            title: 'Deliver generated documents',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/create-delivery.html',
          },
          {
            title: 'Start process',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/start-process.html',
          },
          {
            title: 'Runs history',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/runs-history.html',
          },
          {
            title: 'Processes examples',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/process-examples.html',
          },
        ],
      },
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
  {
    id: 5,
    route: 'DocumentsProcessEdit',
    templateType: 'XLSX',
    data: [
      {
        name: 'Videos',
        list: [
          {
            title: 'Getting started with processes',
            link: 'https://www.youtube.com/watch?v=tw7eYeYkRQA',
            imageLink: 'https://img.youtube.com/vi/tw7eYeYkRQA/mqdefault.jpg',
            type: 'video',
          },
        ],
      },
      {
        name: 'Articles',
        list: [
          {
            title: 'XLSX template syntax',
            link: 'https://plumsail.com/docs/documents/v1.x/document-generation/xlsx/index.html',
          },
          {
            title: 'Prepare and test a template',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/edit-test-template.html',
          },
          {
            title: 'Configure output file and other settings',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/configure-settings.html',
          },
          {
            title: 'Deliver generated documents',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/create-delivery.html',
          },
          {
            title: 'Start process',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/start-process.html',
          },
          {
            title: 'Runs history',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/runs-history.html',
          },
          {
            title: 'Processes examples',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/process-examples.html',
          },
        ],
      },
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    route: 'DocumentsProcessEdit',
    templateType: 'PPTX',
    data: [
      {
        name: 'Videos',
        list: [
          {
            title: 'Getting started with processes',
            link: 'https://www.youtube.com/watch?v=tw7eYeYkRQA',
            imageLink: 'https://img.youtube.com/vi/tw7eYeYkRQA/mqdefault.jpg',
            type: 'video',
          },
        ],
      },
      {
        name: 'Articles',
        list: [
          {
            title: 'PPTX template syntax',
            link: 'https://plumsail.com/docs/documents/v1.x/document-generation/pptx/index.html',
          },
          {
            title: 'Prepare and test a template',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/edit-test-template.html',
          },
          {
            title: 'Configure output file and other settings',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/configure-settings.html',
          },
          {
            title: 'Deliver generated documents',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/create-delivery.html',
          },
          {
            title: 'Start process',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/start-process.html',
          },
          {
            title: 'Runs history',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/runs-history.html',
          },
          {
            title: 'Processes examples',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/process-examples.html',
          },
        ],
      },
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
  {
    id: 7,
    route: 'DocumentsProcessEdit',
    templateType: 'PDF',
    data: [
      {
        name: 'Videos',
        list: [
          {
            title: 'Getting started with processes',
            link: 'https://www.youtube.com/watch?v=tw7eYeYkRQA',
            imageLink: 'https://img.youtube.com/vi/tw7eYeYkRQA/mqdefault.jpg',
            type: 'video',
          },
        ],
      },
      {
        name: 'Articles',
        list: [
          {
            title: 'Create fillable PDF',
            link: 'https://plumsail.com/docs/documents/v1.x/document-generation/fillable-pdf/index.html',
          },
          {
            title: 'Prepare and test a template',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/edit-test-template.html',
          },
          {
            title: 'Configure output file and other settings',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/configure-settings.html',
          },
          {
            title: 'Deliver generated documents',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/create-delivery.html',
          },
          {
            title: 'Start process',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/start-process.html',
          },
          {
            title: 'Runs history',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/runs-history.html',
          },
          {
            title: 'Processes examples',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/process-examples.html',
          },
        ],
      },
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
  {
    id: 8,
    route: 'DocumentsProcessEdit',
    templateType: 'HTML',
    data: [
      {
        name: 'Videos',
        list: [
          {
            title: 'Getting started with processes',
            link: 'https://www.youtube.com/watch?v=tw7eYeYkRQA',
            imageLink: 'https://img.youtube.com/vi/tw7eYeYkRQA/mqdefault.jpg',
            type: 'video',
          },
        ],
      },
      {
        name: 'Articles',
        list: [
          {
            title: 'HTML template syntax',
            link: 'https://plumsail.com/docs/documents/v1.x/document-generation/html/index.html',
          },
          {
            title: 'Prepare and test a template',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/edit-test-template.html',
          },
          {
            title: 'Configure output file and other settings',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/configure-settings.html',
          },
          {
            title: 'Deliver generated documents',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/create-delivery.html',
          },
          {
            title: 'Start process',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/start-process.html',
          },
          {
            title: 'Runs history',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/runs-history.html',
          },
          {
            title: 'Processes examples',
            link: 'https://plumsail.com/docs/documents/v1.x/user-guide/processes/process-examples.html',
          },
        ],
      },
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
  {
    id: 9,
    route: 'DocumentsProcessesRecycleBin',
    data: [
      {
        name: 'Additional resources',
        list: [
          {
            title: 'Documentation',
            link: 'https://plumsail.com/docs/documents/v1.x/index.html',
          },
          {
            title: 'Community',
            link: 'https://community.plumsail.com/',
          },
          {
            title: 'Support',
            link: 'https://plumsail.com/support',
          },
          {
            title: 'Integrations',
            link: 'https://plumsail.com/documents/integrations',
          },
          {
            title: 'YouTube',
            link: 'https://www.youtube.com/c/Plumsail',
          },
        ],
      },
    ],
  },
];
