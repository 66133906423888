import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export default defineStore('app', () => {
  const route = ref(null);

  const getProduct = computed(() => route.value?.meta?.product
  || route.value?.matched?.[0]?.meta?.product
  || 'plumsail');

  function setRoute(payload) { route.value = payload; }

  return { route, getProduct, setRoute };
});
