import Vue from 'vue';

let isFingerMoved = false;

const stopPropagation = (e: MouseEvent | TouchEvent) => {
  e.stopPropagation();
};

const onTouchMove = () => {
  isFingerMoved = true;
};

export default Vue.directive('touch-self', {
  bind(element, binding) {
    const el = element as HTMLElement & {touchCallback: () => void};

    el.touchCallback = () => {
      if (!isFingerMoved) binding.value();
      isFingerMoved = false;
    };

    el.addEventListener('mousedown', stopPropagation);
    el.addEventListener('mouseup', el.touchCallback);

    el.addEventListener('touchstart', stopPropagation, { passive: true });
    el.addEventListener('touchend', el.touchCallback);

    el.addEventListener('touchmove', onTouchMove, { passive: true });
  },
  unbind(element) {
    const el = element as HTMLElement & {touchCallback: () => void};

    el.removeEventListener('mousedown', stopPropagation);
    el.removeEventListener('mouseup', el.touchCallback);

    el.removeEventListener('touchstart', stopPropagation);
    el.removeEventListener('touchend', el.touchCallback);

    el.removeEventListener('touchmove', onTouchMove);
  },
});
