import { library } from '@fortawesome/fontawesome-svg-core/index.js';
import { faWindowMaximize } from '@fortawesome/free-regular-svg-icons/faWindowMaximize.js';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy.js';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons/faTrashAlt.js';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope.js';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons/faEnvelopeOpen.js';
import { faFileWord } from '@fortawesome/free-regular-svg-icons/faFileWord.js';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons/faFileExcel.js';
import { faFilePowerpoint } from '@fortawesome/free-regular-svg-icons/faFilePowerpoint.js';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons/faFilePdf.js';
import { faFileCode } from '@fortawesome/free-regular-svg-icons/faFileCode.js';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye.js';
import { faSave } from '@fortawesome/free-regular-svg-icons/faSave.js';

library.add(
  faWindowMaximize,
  faCopy,
  faTrashAlt,
  faEnvelope,
  faEnvelopeOpen,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFilePdf,
  faFileCode,
  faEye,
  faSave,
);
