import { defineStore } from 'pinia';
import { Component, ref } from 'vue';

type Breadcrumb = {
  name: string;
  title: string;
};

export default defineStore('formsHeader', () => {
  const pageTitle = ref<string | null>();

  const breadcrumbs = ref<Breadcrumb[]>([]);

  const levelbarActionsComponent = ref<Component | null>(null);

  function setPageBreadcrumb({ name, title }: Breadcrumb) {
    const currentBreadcrumbs = breadcrumbs.value.filter((b) => b.name !== name);

    currentBreadcrumbs.push({ name, title });
    breadcrumbs.value = currentBreadcrumbs;
  }

  function clearPageBreadcrumbs() {
    breadcrumbs.value = [];
  }

  return {
    pageTitle,
    breadcrumbs,
    levelbarActionsComponent,

    setPageBreadcrumb,
    clearPageBreadcrumbs,
  };
});
