import { defineStore } from 'pinia';
import axios from 'axios';
import { apiEndpoint } from '@/utils/url-manager.js';

const api = apiEndpoint;

export default defineStore('templates', {
  state: () => ({
    template: null,
    templates: null,
    templatesCategories: null,
    testTemplate: null,
    htmlSource: null,
  }),

  actions: {
    async readTemplate({ slug }) {
      try {
        const { data } = await axios.get(`${api}documents/processes/templates/slug/${slug}`);

        this.template = data;

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async readTemplates() {
      try {
        const { data } = await axios.get(`${api}documents/processes/templates`);

        this.templates = data;

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async readTemplatesCategories() {
      try {
        const { data } = await axios.get(`${api}documents/processes/templates/categories`);

        this.templatesCategories = data;

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async createTemplate({ type, name }) {
      try {
        const formData = new FormData();

        formData.append('documentType', type);
        formData.append('fileName', name);

        const { data } = await axios.post(`${api}documents/processes/files/from-sample`, formData);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async createTemplateFromHtml({ name, templateData }) {
      try {
        const formData = new FormData();

        formData.append('content', templateData);
        formData.append('fileName', name);
        formData.append('contentType', 'text/html');

        const { data } = await axios.post(`${api}documents/processes/files/from-content`, formData);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async readTestTemplate({ processId }) {
      try {
        const { data } = await axios.get(`${api}documents/processes/${processId}/test`);

        this.testTemplate = data.json;

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async createTestTemplate({ processId, templateData }) {
      try {
        const { data } = await axios.post(`${api}documents/processes/${processId}/test`, templateData);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async uploadTemplate({ file }) {
      try {
        const formData = new FormData();

        formData.append('file', file);

        const { data } = await axios.post(`${api}documents/processes/files`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        return { data, error: null };
      } catch (error) {
        return { data: null, error: true };
      }
    },

    async uploadTemplateFromEditor({ file }) {
      try {
        const formData = new FormData();

        formData.append('file', file);

        const { data } = await axios.post(`${api}documents/processes/editor/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        return { data, error: null };
      } catch (error) {
        return { data: null, error: true };
      }
    },

    async uploadTemplateFromLink({ link }) {
      try {
        const { data } = await axios.post(`${api}documents/processes/files/from-url?url=${encodeURIComponent(link)}`);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: true };
      }
    },

    async readTemplateUrl({ fileId }) {
      try {
        const { data } = await axios.get(`${api}documents/processes/editor/${fileId}/link`);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async readPreviewTemplateUrl({ processId }) {
      try {
        const { data } = await axios.get(`${api}documents/processes/editor/${processId}/content`);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async readPreviewTemplateGoogleDriveId({ processId }) {
      try {
        const { data } = await axios.post(`${api}documents/processes/editor/${processId}/upload`);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async copyTemplate({ fileId }) {
      try {
        const { data } = await axios.post(`${api}documents/processes/editor/${fileId}/copy`);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    // TODO: ask what a difference between 2 validate endpoints
    async validateTemplate({ id }) {
      try {
        const { data } = await axios.get(`${api}documents/processes/validate/template/${id}`);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async validateTemplateForProcess({ processId, id }) {
      try {
        const { data } = await axios.get(`${api}documents/processes/${processId}/validate/template/${id}`);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async getHtmlContent(processId) {
      try {
        const { data: link } = await this.readPreviewTemplateUrl({ processId });
        const { data } = await axios.get(link);
        this.htmlSource = data;
      } catch (error) {
        this.htmlSource = null;
        throw error;
      }
    },
  },
});
