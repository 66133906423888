function getRegion() {
  const storageKey = 'plumsail_region';
  let region = localStorage.getItem(storageKey);
  if (region) {
    return region;
  }
  // au-account.plumsail.com => au-account
  const [regionSegment] = window.location.hostname.split('.');
  if (!regionSegment || !regionSegment.includes('-')) {
    return null;
  }
  // au-account => au
  [region] = regionSegment.split('-');
  if (region) {
    localStorage.setItem(storageKey, region);
  }
  return region;
}

const getDefaultUrl = (u) => {
  if (window.location.hostname === 'localhost') {
    return u;
  }
  return `https://${u}`;
};

const getRegionUrl = (r, u) => `https://${r}-${u}`;

const getUrl = (url) => {
  if (window.location.hostname === 'localhost') {
    return url;
  }

  const region = getRegion();
  return region ? getRegionUrl(region, url) : getDefaultUrl(url);
};

const getUserFormsUrl = (url) => {
  const region = getRegion();
  return `https://${region ? url.replace('{0}', `{0}.${region}`) : url}`;
};

const apiEndpoint = getUrl(process.env.VUE_APP_ApiEndpoint);
const appUrlEndpoint = getUrl(process.env.VUE_APP_AppUrl);

const authEndpoint = getDefaultUrl(process.env.VUE_APP_AuthServer);
const authorizationEndpoint = getDefaultUrl(process.env.VUE_APP_AuthorizationEndpoint);
const tokenEndpoint = getDefaultUrl(process.env.VUE_APP_TokenEndpoint);

const formsUrl = getUrl(process.env.VUE_APP_FormsUrl);
const userFormsUrl = getUserFormsUrl(process.env.VUE_APP_UserFormsUrl);
const formsApiEndpoint = getUrl(process.env.VUE_APP_FormsApiEndpoint);
const formsImageStorageUrl = getUrl(process.env.VUE_APP_FormsImageStorage);
const formsWidgetUrl = getUrl(process.env.VUE_APP_FormsWidgetUrl);
const formsDefaultCommonCssUrl = getUrl(process.env.VUE_APP_FormsDefaultCommonCssUrl);

const workersEndpoint = getUrl(process.env.VUE_APP_WorkersEndpoint);
const workersApiEndpoint = `${workersEndpoint}api/`;

const stripePlumsailApi = getDefaultUrl(process.env.VUE_APP_STRIPE_PLUMSAIL_API);
const stripeBrooklimeApi = getDefaultUrl(process.env.VUE_APP_STRIPE_BROOKLIME_API);

export {
  apiEndpoint,
  appUrlEndpoint,
  authEndpoint,
  authorizationEndpoint,
  tokenEndpoint,
  formsUrl,
  userFormsUrl,
  formsApiEndpoint,
  formsImageStorageUrl,
  formsWidgetUrl,
  formsDefaultCommonCssUrl,
  workersEndpoint,
  workersApiEndpoint,
  stripePlumsailApi,
  stripeBrooklimeApi,
};
