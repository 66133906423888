import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';
import { apiEndpoint } from '@/utils/url-manager.js';

const api = apiEndpoint;

export default defineStore('recycle-bin', () => {
  const deletedProcesses = ref(null);

  async function readDeletedProcesses() {
    try {
      const { data } = await axios.get(`${api}documents/processes/recycle-bin`);

      deletedProcesses.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function restoreDeletedProcess({ id, processName }) {
    try {
      const { data } = await axios.post(`${api}documents/processes/recycle-bin/${id}/restore`, null, {
        headers: {
          ProcessName: processName,
        },
      });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function deleteProcessForever({ id, processName }) {
    try {
      const { data } = await axios.delete(`${api}documents/processes/recycle-bin/${id}`, {
        headers: {
          ProcessName: processName,
        },
      });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  return {
    deletedProcesses,
    restoreDeletedProcess,
    deleteProcessForever,
    readDeletedProcesses,
  };
});
