<template>
  <div
    v-if="getAuth"
    id="app"
    :class="[$style.app, _isScrollDisabled && $style['app--scrollDisabled']]"
  >
    <nprogress-container />

    <template v-if="!loading">
      <modals-container />

      <Navbar
        v-if="!isNavbarHidden"
        :product="getProduct"
      />

      <div>
        <Sidebar
          v-if="!isSidebarHidden"
          :menu="sidebarMenu"
        />

        <section
          :class="[
            $style.content,
            isSidebarHidden && $style['content--wide'],
            $style[$route.meta.contentClass],
          ]"
        >
          <HelpPanel
            v-if="getProduct === 'documents'"
            v-click-outside="closeHelp"
            :open="helpOpen"
            @close="toggleOpen"
          />

          <router-view :key="getSelectedTeam.ownerId" />
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer.vue';
import Navbar from '@/components/navbar.vue';
import Sidebar from '@/components/sidebar.vue';
import HelpPanel from '@/components/help/panel.vue';
import ErrorNotification from '@/components/error-notification.vue';
import ReminderNotification from '@/components/reminder-notification.vue';
import { initThemes, setTheme } from '@/utils/themes.js';
import actions from '@/router/actions.js';
import forms from '@/router/forms.js';
import documents from '@/router/documents.js';
import settings from '@/router/settings.js';
import solidProIcons from './plugins/FontAwesome/solid-pro-icons';
import regularProIcons from './plugins/FontAwesome/regular-pro-icons';
import lightProIcons from './plugins/FontAwesome/light-pro-icons';
import { openNotification } from '@/components/notification-service.js';
import {
  useHelpStore,
  useSettingsStore,
  useTeamsStore,
  useAppStore,
  useScrollStore,
  useErrorsStore,
} from '@/stores/index.js';

export default {
  components: {
    Navbar,
    Sidebar,
    NprogressContainer,
    HelpPanel,
  },

  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useHelpStore, { helpOpen: 'open' }),
    ...mapState(useSettingsStore, ['getAuth']),
    ...mapState(useTeamsStore, ['getSelectedTeam']),
    ...mapState(useAppStore, ['getProduct']),
    ...mapState(useScrollStore, ['isScrollDisabled']),
    ...mapState(useErrorsStore, ['error', 'reminder']),

    isSidebarHidden() {
      return this.$route.meta?.sidebarHidden;
    },
    isNavbarHidden() {
      return this.$route.meta?.navbarHidden;
    },
    sidebarMenu() {
      if (['AccountSettings', 'TeamsSettings', 'AuditLog'].includes(this.$route.name)) {
        return [...settings.filter((x) => x.name !== 'AuditLog')];
      }

      return [actions, forms, documents]
        .find((r) => r.meta.product === this.getProduct)
        ?.children.filter(({ meta: { sidebarName } }) => !!sidebarName);
    },

    _isScrollDisabled() {
      return this.isScrollDisabled;
    },
  },
  watch: {
    getProduct: {
      handler(name) {
        setTheme({ name });

        document.documentElement.style.setProperty('--current-color', `var(--color-${name})`);
      },
      immediate: true,
    },

    error(value) {
      if (value) {
        openNotification(
          {
            message: value.message,
            clearError: () => this.setError(null),
          },
          ErrorNotification,
        );
      }
    },

    reminder(value) {
      if (value) {
        openNotification(
          {
            message: value.message,
            clearReminder: () => this.setReminder(null),
          },
          ReminderNotification,
        );
      }
    },
  },

  async created() {
    await initThemes();

    await solidProIcons();
    await regularProIcons();
    await lightProIcons();

    this.loading = false;
  },
  methods: {
    ...mapActions(useHelpStore, {
      toggleOpen: 'toggleOpen',
      closeHelp: 'close',
    }),
    ...mapActions(useErrorsStore, ['setError', 'setReminder']),
  },
};
</script>

<style lang="scss">
@import '~office-ui-fabric-core/src/sass/Fabric.scss';
@import '~animate.css';

:root {
  --color-plumsail: #8e44ad;
  --color-documents: #7d4bcd;
  --color-forms: #008eff;
  --color-actions: #ce2d7d;
  --color-main: #4a4a4a;
  --color-secondary: #363636;
  --color-gray-dark: #b5b5b5;
  --color-gray: #dbdbdb;
  --color-gray-light: #ededed;
  --color-light: #f5f5f5;
  --color-alabaster: #fafafa;
  --color-white: #fff;
  --color-black: #000;
  --color-danger: #f14668;
  --color-success: #48c78e;
  --transition-duration: 0.2s;
  --current-color: var(--color-plumsail);

  // we need this style to overwrite bulma default html { overflow-y: scroll }
  overflow-y: auto;
}
</style>

<style lang="scss" module>
@import '~bulma/sass/utilities/mixins';

.app {
  background-color: var(--color-white);

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 200px;
    padding: 80px 30px;
    min-height: 100vh;

    > * {
      display: flex;
      flex: 1 0 auto;
      flex-flow: column;
    }

    &--wide {
      margin-left: 0;
    }

    &--documents-editor {
      padding-bottom: 0;
    }

    @include touch {
      margin-left: 46px;
    }

    @include mobile {
      margin-left: 0;
      padding: 60px 10px;
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &--scrollDisabled {
    overflow: hidden;
  }

  .app-content {
    &-forms {
      &-home {
        background-color: #ebebeb;
      }
      &-submissions {
        padding: 80px 0 0 30px;
        @media screen and (max-width: 768px) {
          min-height: calc(100vh - 46px);
          padding: 80px 0 0 13px;
        }
      }
    }
  }
}

:global(div.modal) {
  // TODO: currently move here, in future move it
  // div.modal here for more specificity

  // TODO: rewrite with nested logic
  --border-radius: 2px;

  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  :global(.modal-background) {
    background-color: rgba(0, 0, 0, 0.5);
  }

  :global(.modal-content) {
    width: 440px;
  }

  :global(.modal-card) {
    width: 100%;

    :global(.button) {
      min-width: 75px;
      font-weight: 400;
    }
    :global(.button.auto-width) {
      min-width: auto;
    }
  }

  :global(.modal-card:focus-visible) {
    outline: none;
  }

  :global(.modal-card-head) {
    display: flex;
    padding: 10px 20px;
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-white);
  }

  :global(.modal-card-body) {
    padding: 10px 20px 20px;
  }

  :global(.modal-card-foot) {
    padding: 9px 8px 10px;
    justify-content: flex-end;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: var(--color-white);
  }

  :global(.modal-card-title) {
    margin-right: auto;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    flex: 1 1 auto;
    word-break: normal;
  }

  :global(.modal-card--headless) {
    :global(.modal-card-head) {
      display: none;
    }

    :global(.modal-card-body) {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }

  :global(.modal-close-btn) {
    height: auto;
    color: inherit;
  }

  :global(.modal-card--footless) {
    :global(.modal-card-foot) {
      display: none;
    }

    :global(.modal-card-body) {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }

  :global(.info) {
    display: block;
    font-size: 14px;
    line-height: 22px;

    :global(.error) {
      color: var(--color-danger);
    }
  }

  :global(.modal-close) {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
