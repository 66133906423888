import { defineStore } from 'pinia';

export default defineStore('help', {
  state: () => ({
    open: false,
  }),

  actions: {
    setOpen(payload) {
      // don't open help on mobile
      if (window.innerWidth <= 768 && payload === true) return;

      if (payload === true) {
        const opened = localStorage.getItem('helpOpened');

        if (!opened) {
          localStorage.setItem('helpOpened', true);
          this.open = true;
        }
      } else {
        this.open = false;
      }
    },
    toggleOpen() { this.open = !this.open; },
    close() { this.open = false; },
  },
});
