<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <transition
    name="notification"
    :enter-class="$style.notification_enter"
    :enter-active-class="$style.notification_enter_active"
    :leave-to-class="$style.notification_leave"
    :leave-active-class="$style.notification_leave_active"
    @after-leave="afterLeave"
  >
    <div
      v-if="show"
      :class="[
        'message is-danger',
        $style.wrapper,
      ]"
      @mouseover="clearTimer"
      @mouseout="() => startTimer(duration - 500)"
    >
      <div class="message-body">
        <div :class="$style.inner">
          <div :class="$style.picture">
            <font-awesome-icon :icon="['fal', 'face-thinking']" />
          </div>

          <div>
            <p :class="$style.text">
              An unexpected error has occurred. Contact <a
                href="mailto: support@plumsail.com"
              >support@plumsail.com</a> for assistance.
            </p>
            <p :class="$style.text">
              Error: {{ message }}
            </p>
          </div>
        </div>

        <button
          type="button"
          :class="[
            'button is-ghost modal-close-btn is-shadowless has-text-danger is-small',
            $style.close,
          ]"
          @click="close()"
        >
          <font-awesome-icon :icon="['fal', 'xmark']" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ErrorNotification',
  props: {
    message: { type: String, required: true },
    duration: { type: Number, default: 2500 },
    clearError: { type: Function, required: true },
  },

  data() {
    return {
      timer: null,
      show: false,
    };
  },

  created() {
    const component = this.$mount();
    this.startTimer(this.duration);
    document.body.appendChild(component.$el);
    this.show = true;
  },

  destroyed() {
    this.$el.remove();
  },

  methods: {

    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    startTimer(timeout) {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          this.close();
        }, timeout);
      }
    },
    close() {
      this.show = false;
      this.clearError();
    },
    afterLeave() {
      this.$emit('clearError');
      this.$destroy();
    },
  },
};
</script>
<style lang="scss" module>
  .notification {
    max-height: auto;
    overflow: hidden;

    &_enter,
    &_leave {
      opacity: 0;
      transform: translateX(30px);
    }

    &_enter_active {
      transition: transform .5s linear, opacity .7s linear;
    }
    &_leave_active {
      transition: transform .5s linear, opacity .3s linear;
    }
  }
  .wrapper {
    position: fixed;
    top: 69px;
    right: 16px;
    width: 450px;
    z-index: 99999;

    .close {
      position: absolute;
      top: 8px;
      right: 8px;
    }
    @media(max-width: '576px') {
      left: 0;
      right: 0;
      width: 100%;
    }
  }
  .inner {
    display: flex;
    gap: 21px;
    padding: 11px 30px 12px 1px;
  }
  .picture {
    flex: 0 0 28px;
    font-size: 28px;
    line-height: 29px;
  }
  .text {
    line-height: 1.3;
    word-break: break-word;

    &:not(:last-child) {
      margin-bottom: 21px;
    }
  }
</style>
