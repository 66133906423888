<template>
  <transition name="panel">
    <div
      v-if="open"
      :class="$style.panel"
    >
      <div
        :class="$style.close"
        @click="$emit('close')"
      >
        <font-awesome-icon :icon="['fal', 'xmark']" />
      </div>

      <div
        v-for="block in content.data"
        :key="block.id"
        :class="$style.block"
      >
        <div :class="$style.title">
          {{ block.name }}
        </div>

        <div
          v-for="{
            title, link, type, imageLink,
          } in block.list"
          :key="title"
          :class="$style.item"
        >
          <a
            :href="link"
            target="_blank"
            rel="noopener noreferrer"
            :class="$style.link"
            :title="title"
          >
            <img
              v-if="type === 'video'"
              :src="imageLink"
              :alt="title"
              :class="$style.image"
            >
            <span v-else>{{ title }}</span>
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'pinia';
import { useProcessesStore } from '@/stores/index.js';
import data from '@/data/help.js';

const sameTemplate = ({ template, currentTemplate }) => (currentTemplate
  ? currentTemplate === template : true);

export default {
  name: 'HelpPanel',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useProcessesStore, ['process']),
    templateType() {
      return this.process?.inputType;
    },
    content() {
      const defaultData = data.find(({ route }) => route === 'default');

      return data.find(({ route, templateType }) => route === this.$route.name
        && sameTemplate({ template: templateType, currentTemplate: this.templateType }))
        || defaultData;
    },
  },
};
</script>

<style lang="scss" module>
.panel {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 997;
  padding: 15px 20px;
  width: 340px;
  min-height: calc(100% - 50px);
  box-shadow: 0 4px 16px rgba(10, 10, 10, 0.1);
  background-color: var(--color-white);
  transition: 0.6s transform;

  .block {
    .title {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: var(--color-main);
    }

    .item {
      line-height: 28px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .link {
    display: block;

    .image {
      width: 100%;
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    display: none;
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }

  @media (max-width: 768px) {
    bottom: 0;
    width: 100%;
    height: initial;
    box-shadow: initial;
  }
}
</style>

<style scoped lang="scss">
.panel-enter {
  transform: translateX(110%);
}

.panel-enter-to {
  transform: translateX(0);
}

.panel-leave-to {
  transform: translateX(110%);
}
</style>
