import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import axios from 'axios';
import planListData from '@/views/common/subscriptions/utils/plan-list-data.js';
import {
  checkFirstPromoterReferral,
  roundToDecimals,
  getGAClientId,
  getGASessionId,
} from '@/utils/index.js';
import {
  stripePlumsailApi, stripeBrooklimeApi, apiEndpoint, appUrlEndpoint,
} from '@/utils/url-manager.js';

const endpoint = apiEndpoint;

export default defineStore('subscriptions', () => {
  const loaded = ref(false);
  const license = ref(null);
  const emailNotification = ref(false);
  const billingEmail = ref(null);
  const isRenewal = ref(false);
  const renewalLink = ref(null);
  const currency = ref('');
  const upgradePlans = ref({ loading: false, data: null, error: null });
  const upgradePrice = ref({ loading: false, data: null, error: null });
  const upgradeStatus = ref({ loading: false, data: null, error: null });
  const statusSubscription = ref({ data: null, error: null });
  const planList = ref({ loading: false, data: {}, error: null });
  const isCancelSuccess = ref(null);
  const isResumeSuccess = ref(null);

  const getLicenseId = computed(() => license.value?.avSubscriptionId);
  const getLicensePlan = computed(() => license.value?.plan);
  const getLicenseIsActive = computed(() => license.value?.isActive);
  const getIs2co = computed(() => license.value?.paymentProcessor === 0);
  const getIsTrialLicense = computed(() => getLicensePlan.value === 'Trial');
  const getIsTestOrPartner = computed(() => ['partner', 'test'].includes(getLicenseId.value?.toLowerCase()));
  const getPaymentProcessor = computed(() => license.value?.paymentProcessor);
  const getBillingUrl = computed(() => (license.value?.paymentProcessor === 0
    ? `https://secure.2co.com/myaccount/my_license/?code=${license.value?.avSubscriptionId}`
    : 'https://plumsail.com/store/billing'));
  const getStatusSubscription = computed(() => (statusSubscription.value.data));
  const getErrorStatusSubscription = computed(() => (statusSubscription.value.error));
  const getSubscriptionCancelDate = computed(
    () => (statusSubscription.value.data?.cancelAt || null),
  );
  const paymentProcessorAndLicenseId = computed(() => `${getPaymentProcessor.value}/${getLicenseId.value}`);

  function clearStatusSubscription() { statusSubscription.value = { data: null, error: null }; }
  function clearUpgradePrice() {
    upgradePrice.value = { loading: false, data: null, error: null };
  }
  function clearUpgradePlans() {
    upgradePlans.value = { loading: false, data: null, error: null };
  }

  async function loadLicense({ product }) {
    try {
      const { data } = await axios.get(`${endpoint}${product}/license`);
      license.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    } finally {
      loaded.value = true;
    }
  }

  async function loadSubscription() {
    try {
      const { data } = await axios.get(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}`);

      billingEmail.value = data.billingEmail;
      currency.value = data.currency;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function loadLicenseRenewal() {
    try {
      const { data } = await axios.get(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}/renewal`);

      isRenewal.value = data.recurringEnabled;
      renewalLink.value = data.manualRenewalLink;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updateLicenseRenewal({ value }) {
    try {
      isRenewal.value = value;

      const { data } = await axios.put(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}/auto-renewal/?status=${value}`, null, {
        headers: {
          ga_client_id: getGAClientId(),
          ga_session_id: getGASessionId(),
        },
      });

      return { data, error: null };
    } catch (error) {
      isRenewal.value = !value;

      return { data: null, error: error.response.data };
    }
  }

  async function loadEmailNotification({ product }) {
    try {
      const { data } = await axios.get(`${endpoint}${product}/alerts`);

      emailNotification.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updateEmailNotification({ product, value }) {
    try {
      emailNotification.value = value;

      const { data } = await axios.patch(`${endpoint}${product}/alerts/?enable=${value}`);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function loadUpgradePlans() {
    upgradePlans.value = { loading: true, data: null, error: null };
    try {
      const { data } = await axios.get(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}/upgrade/plans`);
      if (!data || !data.length) throw new Error('empty response');
      upgradePlans.value = { loading: false, data, error: null };
    } catch (error) {
      upgradePlans.value = { loading: false, data: null, error };
    }
  }

  async function loadUpgradePrice({ priceId }) {
    upgradePrice.value = { loading: true, data: null, error: null };
    try {
      const { data } = await axios.get(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}/upgrade/${priceId}/preview`);
      upgradePrice.value = { loading: false, data, error: null };
    } catch (error) {
      upgradePrice.value = { loading: false, data: null, error };
      throw error;
    }
  }

  async function upgradePlan({ priceId }) {
    upgradeStatus.value = { loading: true, data: null, error: null };
    try {
      const { data } = await axios.post(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}/upgrade`, { priceId }, {
        headers: {
          ga_client_id: getGAClientId(),
          ga_session_id: getGASessionId(),
        },
      });
      upgradeStatus.value = { loading: false, data, error: null };
    } catch (error) {
      upgradeStatus.value = { loading: false, data: null, error };
    }
  }

  async function loadStatusSubscription() {
    try {
      const { data } = await axios.get(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}/status`);
      statusSubscription.value = { data, error: null };
    } catch (error) {
      statusSubscription.value = { data: null, error };
    }
  }

  async function cancelSubscription() {
    try {
      await axios.post(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}/cancel`);
      isCancelSuccess.value = true;
    } catch {
      isCancelSuccess.value = false;
    }
  }

  async function resumeSubscription() {
    try {
      await axios.post(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}/resume`);
      isResumeSuccess.value = true;
    } catch {
      isResumeSuccess.value = false;
    }
  }

  async function loadPlanList(category) {
    if (planList.value.data[category]) return;

    planList.value = { ...planList.value, loading: true };
    try {
      const isFirstPromoterReferral = checkFirstPromoterReferral();
      const api = isFirstPromoterReferral
        ? stripePlumsailApi
        : stripeBrooklimeApi;

      const currentProduct = category[0].toUpperCase() + category.slice(1).toLowerCase();
      const { data: productsData } = await axios.get(`${api}products?category=${currentProduct}`);
      const productList = await Promise.all(productsData.map(async (product) => {
        const { data: { prices } } = await axios.get(`${api}products/${product.id}`);

        if (!prices.length) return null;

        if (product.metadata.Plan === 'Extras') {
          const priceInfo = prices.find((price) => price.currency === 'usd');
          return {
            product: category.toLowerCase(),
            name: 'More',
            price: priceInfo.price,
            executions: product.metadata.Executions,
            twoCo: currentProduct === 'Documents' ? '20007AC54MD' : '300AC9D32AD',
            monthly: priceInfo.id,
          };
        }

        const monthlyPriceInfo = prices.find((price) => price.currency === 'usd' && price.interval === 'month');
        const annualPriceInfo = prices.find((price) => price.currency === 'usd' && price.interval === 'year');
        const staticData = planListData(product.metadata.Plan);

        return {
          product: category.toLowerCase(),
          name: product.metadata.Plan,
          monthlyPrice: monthlyPriceInfo.price,
          annualPrice: roundToDecimals(annualPriceInfo.price / 12),
          monthly: monthlyPriceInfo.id,
          annual: annualPriceInfo.id,
          ...staticData,
        };
      }));

      const sortedProductList = productList
        .filter((product) => product !== null)
        .sort((a, b) => (a.monthlyPrice || a.price) - (b.monthlyPrice || b.price));

      if (category.toLowerCase() === 'documents') {
        planList.value = {
          loading: false,
          data: {
            ...planList.value.data,
            [category]: [
              ...sortedProductList,
              {
                product: 'documents',
                name: 'Personal',
                additionalDescription: 'If none of the plans suits, let us know. We will find the best plan for you.',
              },
            ],
          },
          error: null,
        };
      } else {
        planList.value = {
          loading: false,
          data: {
            ...planList.value.data,
            [category]: sortedProductList,
          },
          error: null,
        };
      }
    } catch (error) {
      planList.value = { loading: false, data: planList.value.data, error };
    }
  }

  async function getBillingPortalUrl({ product }) {
    try {
      if (getPaymentProcessor.value === 0) return { isUrl: false };

      const redirectUrl = encodeURI(`${appUrlEndpoint}${product}/subscription`);
      const { data } = await axios.get(`${endpoint}subscriptions/${paymentProcessorAndLicenseId.value}/portal`, { params: { redirectUrl } });

      return data;
    } catch {
      return { isUrl: false };
    }
  }

  return {
    loaded,
    license,
    emailNotification,
    billingEmail,
    isRenewal,
    renewalLink,
    currency,
    upgradePlans,
    upgradePrice,
    upgradeStatus,
    statusSubscription,
    planList,
    isCancelSuccess,
    isResumeSuccess,

    getLicenseId,
    getLicensePlan,
    getLicenseIsActive,
    getIs2co,
    getIsTrialLicense,
    getIsTestOrPartner,
    getPaymentProcessor,
    getBillingUrl,
    getStatusSubscription,
    getErrorStatusSubscription,
    getSubscriptionCancelDate,

    clearStatusSubscription,
    clearUpgradePrice,
    clearUpgradePlans,

    loadLicense,
    loadSubscription,
    loadLicenseRenewal,
    updateLicenseRenewal,
    loadEmailNotification,
    updateEmailNotification,
    loadUpgradePlans,
    loadUpgradePrice,
    upgradePlan,
    loadStatusSubscription,
    cancelSubscription,
    resumeSubscription,
    loadPlanList,
    getBillingPortalUrl,
  };
});
