import { defineStore } from 'pinia';
import axios from 'axios';
import { apiEndpoint } from '@/utils/url-manager.js';

const api = apiEndpoint;

export default defineStore('auditLogs', {
  state: () => ({
    logs: [],
    metadata: null,
    isLastPage: false,
    isFirstQuery: false,
  }),

  actions: {
    clearAuditLogs() { this.logs = []; },

    async downloadAuditLogs({ from, to, isFirstQuery }) {
      try {
        if (isFirstQuery) {
          this.clearAuditLogs();
          this.isLastPage = false;
          this.metadata = null;
        }

        const { data } = await axios.get(
          `${api}audit/${from}/${to}`,
          { headers: { metadata: this.metadata ? JSON.stringify(this.metadata) : null } },
        );

        this.logs.push(...data);
        this.metadata = data[0] ? data[0].metadata : null;
        this.isLastPage = data[0] ? data[0].isLastPage : true;

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },

    async exportAuditToCsv({ from, to }) {
      try {
        const { data } = await axios.get(
          `${api}audit/export-to-csv/${from}/${to}`,
        );

        window.open(`${api}audit/export-to-csv/${data.key}`);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },
  },
});
