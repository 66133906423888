<template>
  <div
    :class="[$style.button, active && $style['button--active']]"
    @click.stop="$emit('click')"
    @touchstart.stop
  >
    <div :class="$style.icon">
      ?
    </div>

    <div :class="$style.text">
      help
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpButton',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.button {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 16px;
  text-transform: uppercase;
  transition-property: background-color;
  transition-duration: var(--transition-duration);
  cursor: pointer;
  user-select: none;

  .icon {
    margin-right: 8px;
    font-weight: 700;
    font-size: 18px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  .text {
    font-weight: 600;
    font-size: 12px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover,
  &--active {
    background-color: var(--color-gray);
  }
}
</style>
