export default {
  name: 'Actions',
  path: '/actions',
  redirect: '/actions/intro',
  meta: {
    product: 'actions',
  },
  component: () => import('@/views/actions/index.vue'),
  children: [
    {
      name: 'Actions_Intro',
      path: 'intro',
      alias: '/actions/intro/reg',
      meta: {
        sidebarName: 'Intro',
        title: 'Plumsail Actions',
        sidebarIcon: 'house',
      },
      component: () => import('@/views/actions/intro.vue'),
    },
    {
      name: 'Actions_APIKeys',
      path: 'api-keys',
      meta: {
        title: 'API Keys',
        sidebarName: 'API Keys',
        sidebarIcon: 'key-skeleton-left-right',
      },
      component: () => import('@/views/actions/apiKeys/index.vue'),
    },
    {
      name: 'Actions_Reports',
      path: 'reports',
      meta: {
        title: 'Reports',
        sidebarName: 'Reports',
        sidebarIcon: 'chart-mixed',
      },
      component: () => import('@/views/common/reports/index.vue'),
    },
    {
      name: 'Actions_Subscription',
      path: 'subscription',
      meta: {
        titleComponent: () => import('@/views/common/subscriptions/components/subscriptions-title.vue'),
        sidebarName: 'Subscription',
        sidebarIcon: 'circle-dollar-to-slot',
      },
      component: () => import('@/views/common/subscriptions/index.vue'),
    },
  ],
};
