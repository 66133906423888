export default (plan) => {
  switch (plan) {
    case 'Dolphin':
      return {
        executions: 500,
        sla: false,
      };
    case 'Narwhal':
      return {
        executions: 2000,
        sla: false,
        popular: true,
      };
    case 'Orca':
      return {
        executions: 5000,
        sla: false,
      };
    case 'Whale':
      return {
        executions: 15000,
        sla: true,
      };
    case 'Folder':
      return {
        description: 'for starters',
        executions: 200,
        sla: false,
      };
    case 'Drawer':
      return {
        description: 'for business',
        executions: 1000,
        popular: true,
        sla: false,
      };
    case 'Cabinet':
      return {
        description: 'for enterprise',
        executions: 3000,
        sla: true,
      };
    default: return {};
  }
};
