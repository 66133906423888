import { all as locales } from 'locale-codes';
import {
  sortWith,
  ascend,
  descend,
  propOr,
  replace,
  toUpper,
  filter,
  compose,
  any,
  includes,
  toLower,
  map,
  flatten,
  values,
} from 'ramda';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import UTC from 'dayjs/plugin/utc.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import { Vue } from 'vue-property-decorator';
import Cookies from 'js-cookie';
import { formsWidgetUrl } from '@/utils/url-manager.js';

// TODO: think about move days to vendors
dayjs.extend(relativeTime);
dayjs.extend(UTC);
dayjs.extend(localizedFormat);

const formatDate = ({ date, format }) => dayjs(date).format(format);
const fromDate = ({ date }) => dayjs(date).fromNow();
const formatDateUTC = ({ date, format }) => dayjs(date).utc().format(format);

const addLocaleTitle = (x) => ({ ...x, title: `${x.name} - ${x.location} (${x.tag})` });

const localeCodes = locales.filter((x) => x['iso639-1'] && x.location).map(addLocaleTitle);
const localeCodesTitles = localeCodes.map((x) => x.title);

const randomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const accumulateTags = (tags) => tags.reduce((acc, item) => {
  let result = [...acc, { label: item.path.join('.'), meta: item.meta }];

  if (item.children.length) {
    result = [...result, ...accumulateTags(item.children)];
  }

  return result;
}, []);

const sortByPriority = sortWith([
  descend(propOr(0, 'priority')),
  ascend(propOr('', 'name')),
]);

const searchWith = (str) => (fn) => filter(
  compose(
    any(includes(toLower(str))),
    compose(
      map(toLower),
      flatten,
      values,
      fn,
    ),
  ),
);

const isProduction = process.env.NODE_ENV === 'production';

const locale = navigator.languages.filter((x) => x.includes('-'))[0] || 'en-US';

const capitalize = replace(/^./, toUpper);

const stringFormat = (s, ...params) => s.replace(/{(\d+)}/g, (match, number) => (typeof params[number] !== 'undefined'
  ? params[number]
  : match));

function getCurrecySymbol(currency) {
  switch (currency?.toUpperCase()) {
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    default:
      return '$';
  }
}

const getUTCDate = (date) => {
  const newDate = new Date(date);

  const dateUTC = Date.UTC(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDate(),
    newDate.getHours(),
    newDate.getMinutes(),
    newDate.getSeconds(),
    newDate.getMilliseconds(),
  );

  return new Date(dateUTC);
};

const formatRelativeDate = (date) => {
  if (date <= new Date(0)) return '';

  const localeOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const localeOptionsForCurrentDay = {
    hour: 'numeric',
    minute: 'numeric',
  };

  const localeOptionsForCurrentYear = {
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  let options = localeOptions;

  if (new Date().getFullYear() === date.getFullYear()) {
    options = localeOptionsForCurrentYear;
  }

  if (new Date().toDateString() === date.toDateString()) {
    options = localeOptionsForCurrentDay;
  }

  return new Intl.DateTimeFormat(undefined, options).format(date);
};

const checkFirstPromoterReferral = () => Boolean(Cookies.get('_fprom_ref') && Cookies.get('_fprom_tid'));

const getFirstPromoterId = () => Cookies.get('_fprom_tid') || `fp_${(new Date()).getTime()}`;

const roundToDecimals = (number, digitsAfterPoint = 1) => (
  (Math.round(number * 10 ** digitsAfterPoint)) / 10 ** digitsAfterPoint
);

const getUtcOffset = (time = null) => {
  const currentDate = new Date();
  const UTCTime = currentDate.toLocaleString('en-US', { timeZone: 'UTC' });
  const offset = Math.floor(((time ? new Date(time) : currentDate) - new Date(UTCTime)) / 60000);
  const hours = new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 }).format(Math.abs(Math.floor(offset / 60)));
  const minutes = new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 }).format(Math.abs(offset % 60));
  return { utcOffset: `UTC${offset >= 0 ? '+' : '-'}${hours}:${minutes}`, offset };
};

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getTimezones = () => {
  const timezones = Intl.supportedValuesOf('timeZone');
  if (!timezones.find((timezone) => timezone === 'UTC')) timezones.push('UTC');
  return timezones.map((name) => {
    if (name === 'UTC') return { name, title: `${name} (UTC+00:00)`, offset: 0 };
    const currentDate = new Date();
    const strTime = currentDate.toLocaleString('en-US', { timeZone: `${name}` });
    const { utcOffset, offset } = getUtcOffset(strTime);
    return { name, title: `${name} (${utcOffset})`, offset };
  }).sort((a, b) => a.offset - b.offset);
};

//GA1.1.2034553364.1679659141 => 2034553364.1679659141
const getGAClientId = () => Cookies.get('_ga').substring(6);

//GS1.1.1686225676.3.0.1686225678.58.0.0 => 1686225676
const getGASessionId = () => Cookies.get('_ga_CKRMJQMBFQ').substring(6, 16);

export const formatFullRelativeDate = (date) => {
  const newDate = new Date(date);

  return new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(newDate);
};

export const createVueComponent = (component, options) => {
  const Component = Vue.extend(component);
  return new Component(options);
};

export const ensureFormsScript = () => {
  const { Plumsail } = window;

  if (Plumsail?.Form) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    const app = document.createElement('script');
    app.src = formsWidgetUrl;
    app.onload = () => {
      resolve();
    };
    document.head.appendChild(app);
  });
};

export {
  localeCodes,
  localeCodesTitles,
  formatDate,
  fromDate,
  formatDateUTC,
  randomString,
  accumulateTags,
  sortByPriority,
  stringFormat,
  capitalize,
  searchWith,
  locale,
  isProduction,
  getCurrecySymbol,
  getUTCDate,
  formatRelativeDate,
  getFirstPromoterId,
  checkFirstPromoterReferral,
  roundToDecimals,
  getTimezones,
  currentTimezone,
  getGAClientId,
  getGASessionId,
};
