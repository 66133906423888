import { createVueComponent } from '@/utils/index.js';
import Notification from './notification.vue';

export const openNotification = (propsData = {
  title: '',
  message: '',
  type: '',
  direction: 'Top',
  duration: 3500,
  container: '.notifications',
}, component = Notification) => createVueComponent(component, {
  el: document.createElement('div'),
  propsData,
});

export default {};
