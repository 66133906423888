import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export default ({ settingsStore, teamsStore, pinia }) => () => {
  const settings = settingsStore(pinia);
  const teams = teamsStore(pinia);
  axios.interceptors.request.use((config) => {
    const newConfig = { ...config };
    const { user } = settings;

    if (user
        && (config.url.startsWith(config.baseURL) || config.url.startsWith(config.baseFormsUrl))) {
      newConfig.headers.Authorization = `${user.token_type} ${user.access_token}`;

      const teamId = teams.getSelectedTeam?.ownerId;

      if (teamId) {
        newConfig.headers['X-Team'] ??= teamId;
      }
    }
    return newConfig;
  }, (error) => {
    if (error.response.status >= 500) {
      error.response.data = 'Something went wrong. Please contact support@plumsail.com';
    }

    return Promise.reject(error);
  });

  axios.interceptors.response.use((response) => {
    if (response.headers['region-location']) {
      window.location.href = response.headers['region-location'];
    }

    return response;
  }, (error) => Promise.reject(error));

  createAuthRefreshInterceptor(axios, async (failedRequest) => {
    const { data } = await settings.loginSilent();

    if (!data) {
      settings.logoutAccount();
      return;
    }

    settings.setUser(data);
    failedRequest.response.config.headers.Authorization = `${data.token_type} ${data.access_token}`;
  }, { pauseInstanceWhileRefreshing: true });
};
