import { defineStore } from 'pinia';
import useProcessesStore from './processes';

export default defineStore('processSteps', {
  namespaced: true,

  state: () => ({
    isStepCreating: false,
    currentSubStep: 0,
    validateStep: null,
    newStepIndex: -1,
    currentStep: 0,
    subStep: -1,
    steps: [],
    step: -1,
    tagsBuffer: null,
  }),

  getters: {
    getCurrentStepId: (state) => (
      state.steps[state.currentStep]?.settings.propsData.stepId
      || null
    ),
  },

  actions: {
    addNewStep(payload) {
      this.isStepCreating = false;

      const steps = [...this.steps];
      steps.splice(this.newStepIndex, 0, payload);
      this.steps = steps.map((step, index) => {
        if (index === this.currentStep) {
          return { ...step, isActive: false };
        }

        if (index === this.newStepIndex) {
          return { ...step, isActive: true };
        }

        return step;
      });
      this.currentStep = this.newStepIndex;
      this.newStepIndex = -1;
    },

    createStep() {
      this.newStepIndex = this.steps.length - 1;
      this.validateStep = this.getCurrentStepId;
    },

    cancelCreation() {
      this.isStepCreating = false;
      const steps = this.steps.map((step, index) => {
        if (index === this.currentStep) {
          return { ...step, isActive: false };
        }

        if (index === this.steps.length - 1) {
          return { ...step, isActive: true };
        }

        return step;
      });
      this.steps = steps;

      this.currentStep = this.steps.length - 1;
    },

    deleteStep(payload = null) {
      if (payload) {
        const steps = this.steps.filter((_, index) => index !== payload);
        this.steps = steps;
        this.navigatePrevious();
      }
    },

    changeStep(payload) {
      this.isStepCreating = false;
      const steps = this.steps.map((step, index) => {
        if (index === payload) {
          return { ...step, isActive: true };
        }

        if (index === this.currentStep) {
          return { ...step, isActive: false };
        }

        return step;
      });
      this.steps = steps;

      this.currentStep = payload;
    },

    validityChecked(payload) {
      if (this.newStepIndex !== -1) {
        this.isStepCreating = true;
        return;
      }

      if (this.step !== -1) {
        const steps = this.steps.map((step, index) => {
          if (index === this.currentStep) {
            return {
              ...step,
              isActive: payload.isInvalid,
              isInvalid: payload.isInvalid,
              isFinished: payload.isFinished,
            };
          }

          return step;
        });
        this.steps = steps;

        if (payload.isFinished) {
          this.currentSubStep = 0;
          this.currentStep = this.step;

          const newSteps = this.steps.map((step, index) => {
            if (index === this.step) {
              return { ...step, isActive: true };
            }
            return step;
          });
          this.steps = newSteps;

          this.step = -1;
        }
      } else if (this.subStep !== -1) {
        if (payload.isFinished) {
          if (this.subStep > this.currentSubStep) {
            this.currentSubStep += 1;
          } else {
            this.currentSubStep -= 1;
          }

          this.subSubStep = -1;
        }
      }
    },

    navigatePrevious() {
      if (this.currentStep === 0) {
        return;
      }

      const steps = this.steps.map((step, index) => {
        if (index === this.currentStep) {
          return { ...step, isActive: false };
        }
        if (index === this.currentStep - 1) {
          return { ...step, isActive: true };
        }
        return step;
      });

      this.steps = steps;
      this.currentStep -= 1;
    },

    navigateNext() {
      if (this.currentStep + 1 >= this.steps.length) {
        return;
      }

      if (this.steps.length === 2) {
        this.createStep();
        return;
      }

      const steps = this.steps.map((step, index) => {
        if (index === this.currentStep) {
          return { ...step, isActive: false };
        }

        if (index === this.currentStep + 1) {
          return { ...step, isActive: true };
        }

        return step;
      });
      this.steps = steps;

      this.currentStep += 1;
    },

    selectSubstep(subStepId) {
      if (subStepId === this.currentSubStep) {
        return;
      }

      this.subStep = subStepId;
      this.validateStep = this.getCurrentStepId;
    },

    navigateNextSubstep() {
      this.subStep = this.currentSubStep + 1;
      this.validateStep = this.getCurrentStepId;
    },

    async selectStep({ stepIndex, confirm = async () => true }) {
      this.newStepIndex = -1;
      if (this.isStepCreating) {
        const processesStrore = useProcessesStore();
        const { processIsEdited } = processesStrore.$state;
        if (processIsEdited) {
          const isConfirm = await confirm();
          if (isConfirm) {
            processesStrore.$patch((state) => { state.processIsEdited = false; });
          } else return;
        }
        this.changeStep(stepIndex);

        return;
      }
      if (stepIndex === this.currentStep) return;
      this.step = stepIndex;
      this.validateStep = this.getCurrentStepId;
    },

    destroySteps() {
      this.$reset();
    },
  },
});
