import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret.js';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars.js';
import { faParagraph } from '@fortawesome/free-solid-svg-icons/faParagraph.js';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload.js';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs.js';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle.js';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo.js';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen.js';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay.js';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck.js';
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode.js';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt.js';
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask.js';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload.js';
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress.js';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand.js';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle.js';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown.js';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH.js';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV.js';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt.js';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit.js';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus.js';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons/faChevronCircleDown.js';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp.js';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown.js';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle.js';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync.js';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown.js';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch.js';
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock.js';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock.js';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy.js';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons/faGripVertical.js';
import { faLevelUpAlt } from '@fortawesome/free-solid-svg-icons/faLevelUpAlt.js';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons/faFolderPlus.js';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard.js';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes.js';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons/faAlignJustify.js';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome.js';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser.js';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog.js';

library.add(
  faUserSecret,
  faBars,
  faParagraph,
  faUpload,
  faCogs,
  faPlusCircle,
  faRedo,
  faPen,
  faPlay,
  faCheck,
  faCode,
  faSignOutAlt,
  faFlask,
  faDownload,
  faCompress,
  faExpand,
  faCheckCircle,
  faAngleDown,
  faEllipsisH,
  faEllipsisV,
  faExternalLinkAlt,
  faEdit,
  faPlus,
  faChevronCircleDown,
  faChevronUp,
  faChevronDown,
  faInfoCircle,
  faSync,
  faArrowDown,
  faSearch,
  faUnlock,
  faLock,
  faCopy,
  faGripVertical,
  faLevelUpAlt,
  faFolderPlus,
  faCreditCard,
  faTimes,
  faAlignJustify,
  faHome,
  faUser,
  faCog,
);
