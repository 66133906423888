import { defineStore } from 'pinia';

export default defineStore('errors', {
  state: () => ({
    error: null,
    reminder: null,
  }),

  actions: {
    setError(value) {
      this.error = value;
    },

    setReminder(value) {
      this.reminder = value;
    },
  },
});
