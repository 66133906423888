import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import {
  __,
  complement,
  compose,
  trim,
  sortBy,
  filter,
  map,
  path,
  init,
  split,
  allPass,
  includes,
  reduce,
  addIndex,
  any,
  anyPass,
} from 'ramda';
import axios from 'axios';
import { apiEndpoint } from '@/utils/url-manager.js';

const api = apiEndpoint;

const reduceWithIndex = addIndex(reduce);
const filterWithIndex = addIndex(filter);

const getType = path(['meta', 'type']);
const getLabel = path(['label']);
const trimLabel = compose(trim, getLabel);
const isPrimitive = complement(includes(__, ['object', 'collection']));
const isNested = includes('.');
const isPrimitiveToken = compose(isPrimitive, getType);
const isNestedToken = compose(isNested, getLabel);
const formatParentNames = (acc, el, i) => (i === 0 ? [...acc, el] : [...acc, `${acc[i - 1]}.${el}`]);
const getParentNames = compose(reduceWithIndex(formatParentNames, []), init, split('.'));
const getParentsNamesFromLabel = compose(getParentNames, getLabel);
const getParentObjects = (arr) => (primitive) => filter(
  (x) => includes(getLabel(x), primitive),
  arr,
);
const isTokenHaveParentCollection = (arr) => (x) => isNestedToken(x)
  && compose(
    anyPass([any((y) => getType(y) === 'collection')]),
    getParentObjects(arr),
    getParentsNamesFromLabel,
  )(x);
const filterTokens = (e, i, arr) => allPass(
  [isPrimitiveToken, complement(isTokenHaveParentCollection(arr))],
)(e);

export default defineStore('tokens', () => {
  const systemTokens = ref([
    {
      label: '@date',
      meta: { type: 'date', immutable: true, description: 'current date' },
    },
    {
      label: '@number',
      meta: { type: 'number', immutable: true, description: 'document number index' },
    },
  ]);

  const tokens = ref([]);

  const sortedTokens = computed(() => compose(
    sortBy(getLabel),
    filterWithIndex(filterTokens),
    map((x) => ({ ...x, label: trimLabel(x) })),
  )([...systemTokens.value, ...tokens.value]));

  async function readTokens({ processId }) {
    try {
      const { data } = await axios.get(`${api}documents/processes/tags/${processId}`);
      tokens.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async function readTokensFromTemplate({ templateId, updateTags = false }) {
    try {
      const { data } = await axios.get(`${api}documents/processes/tags/from-url?templateId=${templateId}`);

      if (updateTags) {
        tokens.value = data;
      }

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error?.response?.data?.error ?? error };
    }
  }

  async function updateTokens({ processId, tokensData }) {
    try {
      const { data } = await axios.post(`${api}documents/processes/editor/${processId}/update-test-form`, tokensData);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  return {
    systemTokens,
    tokens,
    sortedTokens,
    readTokens,
    readTokensFromTemplate,
    updateTokens,
  };
});
