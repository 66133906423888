import axios from 'axios';
import Cookie from 'js-cookie';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import useSettingsStore from './settings';
import { apiEndpoint } from '@/utils/url-manager.js';

const baseEndpoint = `${apiEndpoint}account/teams/`;

const cookieTeamId = Cookie.get('SelectedTeam');
const storageTeamId = localStorage.getItem('teamId');
const storageTeamName = localStorage.getItem('teamName');

let selectedTeamCookie = cookieTeamId && { ownerId: cookieTeamId };

if (!selectedTeamCookie && storageTeamId && storageTeamName) {
  selectedTeamCookie = { name: storageTeamName, ownerId: storageTeamId } || null;
}

Cookie.remove('SelectedTeam', { path: '/', domain: 'plumsail.com' });

export default defineStore('teams', () => {
  const ownTeam = ref(null);
  const availableTeams = ref(null);
  const selectedTeam = ref(selectedTeamCookie);

  const settingsStore = useSettingsStore();

  const getSelectedTeam = computed(() => selectedTeam.value
    || { ownerId: settingsStore.getProfile?.sub, name: settingsStore.getProfile?.name });
  const getOwnTeamSelected = computed(
    () => selectedTeam.value?.ownerId === settingsStore.getProfile?.sub,
  );

  function setSelectedTeam({ ownerId, name }) {
    localStorage.setItem('teamId', ownerId);
    localStorage.setItem('teamName', name);

    selectedTeam.value = { ownerId, name };
  }

  async function readOwnTeam() {
    try {
      const { data } = await axios.get(`${baseEndpoint}own`);

      ownTeam.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readTeams() {
    try {
      const { data } = await axios.get(`${baseEndpoint}`);

      availableTeams.value = data;

      // this need to just update name field in selectedTeam, because we
      // originally dot't have name field in cookie (only teamId set in auth ui)
      // mb in future add cookie with name field
      setSelectedTeam(availableTeams.value.find(
        ({ ownerId }) => ownerId === getSelectedTeam.value.ownerId,
      ));

      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async function sendInvitation({ email }) {
    try {
      const { data } = await axios.post(`${baseEndpoint}invitations`, { email });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function deleteTeamUser({ userId }) {
    try {
      const { data } = await axios.delete(`${baseEndpoint}members/${userId}`);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function deleteInvitations({ email }) {
    try {
      const { data } = await axios.delete(`${baseEndpoint}invitations`, { headers: { email } });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updateTeamName({ name }) {
    try {
      const { data } = await axios.put(`${baseEndpoint}`, { name });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function leaveTeam({ ownerId }) {
    try {
      const { data } = await axios.delete(`${baseEndpoint}leave/${ownerId}`);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  return {
    ownTeam,
    availableTeams,
    selectedTeam,
    getSelectedTeam,
    getOwnTeamSelected,
    setSelectedTeam,
    readOwnTeam,
    readTeams,
    sendInvitation,
    deleteTeamUser,
    deleteInvitations,
    updateTeamName,
    leaveTeam,
  };
});
