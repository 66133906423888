/* eslint-disable @typescript-eslint/no-shadow */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import axios from 'axios';
import { workersApiEndpoint, apiEndpoint } from '@/utils/url-manager.js';

const api = apiEndpoint;
const publicApi = workersApiEndpoint;

export default defineStore('processes', () => {
  const processes = ref(null);
  const process = ref(null);
  const jobs = ref([]);
  const job = ref(null);
  const deliveries = ref(null);
  const processIsEdited = ref(false);
  const processTestingMode = ref('');
  const forms = ref(null);
  const docNumber = ref(0);
  const shortUserId = ref(null);
  const continuationToken = ref(null);
  const isLastJobsPage = ref(false);

  const processName = computed(() => process.value?.processName);
  const processId = computed(() => process.value?.id);

  async function readProcesses() {
    try {
      const { data } = await axios.get(`${api}documents/processes`);

      const processedData = data.map((currenProcess) => ({
        ...currenProcess,
        processMode: currenProcess.processMode === 'Active' ? 'Production' : currenProcess.processMode,
      }));

      processes.value = processedData;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readProcess({ id }) {
    try {
      const { data } = await axios.get(`${api}documents/processes/${id}`);
      const mode = data.processMode;
      data.processMode = mode === 'Active' ? 'Production' : mode;

      process.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readDocumentNumber(id) {
    try {
      const { data } = await axios.get(`${api}documents/processes/${id}/document-number`);

      return { number: data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function createProcess(payload) {
    try {
      const { data } = await axios.post(`${api}documents/processes`, payload);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function createProcessWithTemplate({
    templateId,
    processName,
    locale,
    generateForm,
    timezone,
  }) {
    try {
      const { data } = await axios.post(`${api}documents/processes/${templateId}`, {
        processName,
        locale,
        generateForm,
        timezone,
      });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function deleteProcess({ id, processName }) {
    try {
      const { data } = await axios.delete(`${api}documents/processes/${id}`, {
        headers: {
          ProcessName: processName,
        },
      });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updateProcess({ id, processData }) {
    try {
      const mode = processData.processMode;
      const payload = {
        ...processData,
        processMode: mode === 'Production' ? 'Active' : mode,
        documentNumber: docNumber.value,
      };

      const { data } = await axios.post(`${api}documents/processes/${id}/settings`, payload);

      docNumber.value = 0;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updateTestProcess({ id, processData }) {
    try {
      const { data } = await axios.post(`${api}documents/processes/${id}/test/settings`, processData);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function copyProcess({ id }) {
    try {
      const { data } = await axios.post(`${api}documents/processes/${id}/duplicate`);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function startProcess({ id, processData }) {
    try {
      const { data } = await axios.post(`${api}documents/processes/${id}/start`, processData);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function alternateStartProcess({ id, shortUserId, processData }) {
    try {
      const { data } = await axios.post(`${api}documents/test/${shortUserId}/${id}/start`, processData);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updateProcessName({ id, name }) {
    try {
      const { data } = await axios.patch(`${api}documents/processes/${id}/name?value=${name}`);

      process.value = { ...process.value, processName: name };

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readJobs({ processId, isAppend = false }) {
    try {
      const { data } = await axios.get(
        `${api}documents/processes/${processId}/logs/jobs`,
        { headers: { continuationToken: isAppend ? continuationToken.value : null } },
      );

      jobs.value = isAppend ? [...jobs.value, ...data.jobs] : data.jobs;
      continuationToken.value = data.continuationToken || null;
      isLastJobsPage.value = data.isLastPage;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readJob({ processId, jobId, namePrefix }) {
    try {
      const { data } = await axios.get(`${api}documents/processes/${processId}/logs/jobs/${jobId}?namePrefix=${namePrefix}`);

      job.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readDeliveries({ processId }) {
    try {
      const { data } = await axios.get(`${api}documents/processes/${processId}/delivery/deliveries`);

      deliveries.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function deleteDelivery({ processId, deliveryId }) {
    try {
      const { data } = await axios.delete(`${api}documents/processes/${processId}/delivery/${deliveryId}`);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function createOrUpdateDelivery({ processId, deliveryName, deliveryData }) {
    try {
      const { data } = (deliveryName === 'email' && deliveryData.id)
        ? await axios.put(`${api}documents/processes/${processId}/delivery/email/${deliveryData.id}`, deliveryData)
        : await axios.post(`${api}documents/processes/${processId}/delivery/${deliveryName}`, deliveryData);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readForms({ processId }) {
    try {
      const { data } = await axios.get(`${api}documents/processes/${processId}/forms`);

      forms.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function createForm({ processId }) {
    try {
      const { data } = await axios.post(`${api}documents/processes/${processId}/forms/create`);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function deleteForm({ id, processId }) {
    try {
      const { data } = await axios.delete(`${api}documents/processes/${processId}/forms/${id}`);

      forms.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readPdfSettings({ processId }) {
    try {
      const { data } = await axios.get(`${api}documents/processes/${processId}/security/settings`);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updatePdfSettings({ processId, settings }) {
    try {
      const { data } = await axios.post(`${api}documents/processes/${processId}/security/settings`, settings);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readJobIdFromSubmission({ processId, submissionId }) {
    try {
      const { data } = await axios.get(`${api}documents/processes/${processId}/forms/${submissionId}/job-id`);

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function getDefaultFormIds({ processId }) {
    try {
      const { data } = await axios.get(`${api}forms/${processId}/default`);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async function testProcess({ processId, userId, payload }) {
    try {
      const { data } = await axios.post(`${api}documents/test/${userId}/${processId}/test`, payload);
      if (!data?.link) {
        throw new Error('Failed to test process');
      }
      return { link: data.link, error: null };
    } catch (error) {
      return { link: null, error: error.message || error };
    }
  }

  async function updateShortUserId() {
    try {
      const { data } = await axios.get(`${api}documents/processes/shortId`);
      shortUserId.value = data;
    } catch {
      shortUserId.value = null;
    }
  }

  async function getResultJob({ location, jobId }) {
    const needReplaceHttp = location && /^http:\/\/([a-zA-Z]+-)?api\.plumsail\.com/.test(location);
    const requestUrl = location || `${publicApi}v2/processes/jobs/${jobId}`;
    try {
      const { data, status } = await axios.get(
        needReplaceHttp ? requestUrl.replace('http://', 'https://') : requestUrl,
        { showProgressBar: false },
      );
      return { data, status, error: null };
    } catch (error) {
      return { data: null, status: 'error', error: error.response?.data?.error || '' };
    }
  }

  async function publicStartProcessAsync({ id, processData }) {
    try {
      const { headers: { location } } = await axios.post(
        `${publicApi}v2/processes/jobs/${shortUserId.value}/${id}/start`,
        processData,
        { showProgressBar: false },
      );

      return { data: { location }, error: null };
    } catch (error) {
      return { data: null, error: error.response?.data?.error || '' };
    }
  }

  async function checkSmtpSettings(payload) {
    try {
      await axios.post(`${api}documents/processes/emailsmtp/connect`, payload);
      return { error: null };
    } catch (error) {
      return { error: error.response?.data?.error || { message: error.code } };
    }
  }

  async function getAmazonRegions() {
    try {
      const { data } = await axios.get(`${api}documents/processes/amazon/regions`);
      return { data, error: null };
    } catch (error) {
      return { error: error.response?.data?.error || { message: error.code } };
    }
  }

  return {
    processTestingMode,
    continuationToken,
    processIsEdited,
    isLastJobsPage,
    shortUserId,
    processName,
    deliveries,
    processId,
    docNumber,
    processes,
    process,
    forms,
    jobs,
    job,

    createProcessWithTemplate,
    readJobIdFromSubmission,
    publicStartProcessAsync,
    createOrUpdateDelivery,
    alternateStartProcess,
    readDocumentNumber,
    getDefaultFormIds,
    updatePdfSettings,
    updateProcessName,
    updateTestProcess,
    updateShortUserId,
    checkSmtpSettings,
    getAmazonRegions,
    readPdfSettings,
    deleteDelivery,
    readDeliveries,
    updateProcess,
    deleteProcess,
    createProcess,
    readProcesses,
    startProcess,
    getResultJob,
    testProcess,
    copyProcess,
    readProcess,
    deleteForm,
    createForm,
    readForms,
    readJob,
    readJobs,
  };
});
