import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export default defineStore('scroll', () => {
  const tasks = ref([]);
  const isScrollDisabled = computed(() => tasks.value.length > 0);

  function enableScroll(payload) { tasks.value.push(payload); }
  function disableScroll(payload) { tasks.value = tasks.value.filter((task) => task !== payload); }

  return {
    tasks,
    isScrollDisabled,
    enableScroll,
    disableScroll,
  };
});
