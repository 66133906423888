<template>
  <b-dropdown
    v-if="getSelectedTeam"
    :class="$style.teamSelector"
    :mobile-modal="false"
  >
    <template #trigger>
      <div :class="$style['icon-wrapper']">
        <font-awesome-icon
          :icon="getOwnTeamSelected ? ['fal', 'house'] : ['fal', 'user']"
          :class="[$style.icon, getOwnTeamSelected && $style['icon--own']]"
        />
      </div>

      <div
        :class="$style.name"
        :title="getSelectedTeam.name"
      >
        {{ getSelectedTeam.name }}
      </div>

      <font-awesome-icon
        :icon="['fal', 'chevron-down']"
        :class="$style.chevron"
      />
    </template>

    <b-dropdown-item
      v-for="{ ownerId, name } in teamsList"
      :key="ownerId"
      :title="name"
      @click="setSelectedTeam({ ownerId, name })"
    >
      <div :class="$style['icon-wrapper']">
        <font-awesome-icon
          :icon="isOwner({ ownerId }) ? ['fal', 'house'] : ['fal', 'user']"
          :class="[$style.icon, isOwner({ ownerId }) && $style['icon--own']]"
        />
      </div>

      <span :class="$style['dropdown-item-text']">{{ name }}</span>
    </b-dropdown-item>

    <b-dropdown-item has-link>
      <router-link
        :to="{ name: 'TeamsSettings' }"
        class="router-link"
      >
        <div :class="$style['manage-team']">
          <div :class="$style['icon-wrapper']">
            <font-awesome-icon
              :class="[$style.icon, $style['icon--custom']]"
              :icon="['fal', 'gear']"
            />
          </div>
          <span :class="$style['dropdown-item-text']">Manage teams</span>
        </div>
      </router-link>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useSettingsStore, useTeamsStore } from '@/stores/index.js';

export default {
  name: 'TeamSelector',
  computed: {
    ...mapState(useTeamsStore, ['availableTeams', 'getSelectedTeam', 'getOwnTeamSelected']),
    ...mapState(useSettingsStore, ['getProfile']),
    teamsList() {
      return (
        this.availableTeams?.filter(
          ({ ownerId }) => ownerId !== this.getSelectedTeam?.ownerId,
        ) || []
      );
    },
  },
  created() {
    this.readTeams();
  },
  methods: {
    ...mapActions(useTeamsStore, ['readTeams', 'setSelectedTeam']),
    isOwner({ ownerId }) {
      return this.getProfile?.sub === ownerId;
    },
  },
};
</script>

<style lang="scss" module>
.teamSelector {
  display: flex;
  align-items: center;
  min-width: 0;

  .icon-wrapper {
    max-width: 14px;
    min-width: 14px;

    .icon {
      object-fit: contain;
      width: 100%;

      color: #167df0;

      &--own {
        color: #f1681b;
      }

      &--custom {
        color: #707070;
      }
    }
  }

  .manage-team {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--color-secondary);
  }

  .chevron {
    path {
      fill: var(--color-secondary);
    }
  }

  :global(.dropdown-trigger) {
    display: flex;
    align-items: center;
    align-self: stretch;
    max-width: 300px;
    overflow-x: clip;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 0.375rem 1rem;
    column-gap: 5px;
  }

  :global(.dropdown-menu) {
    padding-top: 2px;
    min-width: 100%;
    max-width: 270px;
  }

  :global(.dropdown-content) {
    overflow: hidden;
    :global(.has-link .router-link) {
      padding-right: 2rem;
    }
  }

  :global(.dropdown-item) {
    column-gap: 5px;
    display: flex;
    align-items: center;
    padding-right: 1.5rem;
  }

  .dropdown-item-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 410px) {
  .teamSelector {
    :global(.dropdown-menu) {
      max-width: calc(100% + ((100vw - 100%) / 2) - 8px);
    }
  }
}
</style>
