/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import axios from 'axios';
import Cookie from 'js-cookie';
import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { OIDC } from '@/api/auth.js';
import { apiEndpoint, authEndpoint, formsApiEndpoint } from '@/utils/url-manager.js';

const baseAuthEndpoint = `${authEndpoint}api/account/`;
const endpoint = `${apiEndpoint}account/users/`;
const formsDomainEndpoint = `${formsApiEndpoint}v2/user/domain`;

export default defineStore('settings', () => {
  const user = ref(null);
  const userTryLoading = ref(false);
  const formsDomain = ref(null);
  const profileIsUpdated = ref(false);

  const getAuth = computed(() => user.value && !user.value.expired);
  const getProfile = computed(() => (profileIsUpdated.value ? user.value.profile : JSON.parse(localStorage.getItem('userProfile'))));

  OIDC.events.addSilentRenewError(async () => {
    await logout();
  });

  OIDC.events.addUserLoaded((usr) => {
    setUser(usr);
  });

  watch(() => user.value?.profile, (newProfile, oldProfile) => {
    if (!oldProfile && localStorage.getItem('userProfile')) {
      // page has been reloaded
      return;
    }

    if (newProfile) {
      localStorage.setItem('userProfile', JSON.stringify(newProfile));
    }
  }, { deep: true });

  async function userData() {
    try {
      const { data } = await axios.get(
        `${authEndpoint}connect/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${user.value.access_token}`,
          },
          withCredentials: true,
        },
      );

      user.value.profile = data;

      return { data, error: null };
    } catch (error) {
      await logout();
    }
  }

  function setUser(payload) {
    if (payload) {
      Cookie.set('AccountEmail', payload.profile.email, { expires: 1, path: '/', domain: 'plumsail.com', secure: true });
    } else {
      Cookie.remove('AccountEmail', { path: '/', domain: 'plumsail.com' });
    }

    user.value = payload;
  }

  async function userProfile() {
    try {
      let data;
      if (localStorage.getItem('userProfile')) {
        data = JSON.parse(localStorage.getItem('userProfile'));
      } else {
        user.value.profile = user.value?.profile ?? (await OIDC.getUser()).profile;
        data = user.value.profile;
      }

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function logoutAccount() {
    // get current access token to invalidate the last session (after OIDC automaticSilentRenew)
    user.value = await OIDC.getUser();

    if (user.value) {
      try {
        await axios.get(
          `${baseAuthEndpoint}logout`,
          {
            headers: {
              Authorization: `Bearer ${user.value.access_token}`,
            },
            withCredentials: true,
          },
        );
      } catch {
        // ignore
      }
    }

    await logout();
  }

  async function logout() {
    try {
      localStorage.removeItem('teamId');
      localStorage.removeItem('teamName');
      localStorage.removeItem('userProfile');
      OIDC.signoutRedirect();
    } catch (error) {
      return { data: null, error: error.response.data };
    } finally {
      setUser(null);
    }
  }

  async function updateUsername({ name }) {
    try {
      const { data } = await axios.patch(`${endpoint}manage/update/name`, {
        userName: name,
      });

      user.value.profile.name = name;
      profileIsUpdated.value = true;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function readFormsDomain() {
    try {
      const { data } = await axios.get(formsDomainEndpoint, {
        headers: {
          'X-Team': '',
        },
      });

      formsDomain.value = data;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updateFormsDomain({ domain }) {
    try {
      const { data } = await axios.put(formsDomainEndpoint, { domain });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function deleteAccount({ feedback, password }) {
    try {
      const { data } = await axios.delete(`${endpoint}manage/account`, {
        data: {
          feedback,
          password,
        },
      });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updateEmail({ email }) {
    try {
      const { data } = await axios.post(`${endpoint}manage/email/edit`, { email }, {
        withCredentials: true,
      });

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function updateEmailConfirm({ email, code }) {
    try {
      const { data } = await axios.patch(`${endpoint}manage/email/edit/confirm`, {
        newEmail: email,
        confirmCode: code,
      }, { withCredentials: true });

      user.value.profile.email = email;
      profileIsUpdated.value = true;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function tryEnter() {
    try {
      if (userTryLoading.value || user.value) return { user };

      const userLocal = await OIDC.getUser();

      if (userLocal && !userLocal.expired) {
        user.value = userLocal;
        await userData();
        return { user: user.value };
      }

      await OIDC.signinSilentCallback();
      const data = await OIDC.signinSilent();

      setUser(data);

      return { user: data };
    } catch (error) {
      setUser(null);

      return { user: null };
    } finally {
      userTryLoading.value = true;
    }
  }

  async function twoFaLink() {
    try {
      const { data } = await axios.get(`${baseAuthEndpoint}users/manage/2fa/link`, { withCredentials: true });
      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function enable2Fa(code) {
    try {
      const { data } = await axios.post(`${baseAuthEndpoint}users/manage/2fa/enable/${code}`, null, { withCredentials: true });
      user.value.profile.twoFactorEnabled = true;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function disable2Fa(code) {
    try {
      const { data } = await axios.post(`${baseAuthEndpoint}users/manage/2fa/disable/${code}`, null, { withCredentials: true });
      user.value.profile.twoFactorEnabled = false;

      return { data, error: null };
    } catch (error) {
      return { data: null, error: error.response.data };
    }
  }

  async function loginSilent() {
    try {
      const data = await OIDC.signinSilent();

      return { data, error: null };
    } catch (error) {
      logout();
      return { data: null, error: true };
    }
  }

  return {
    user,
    userTryLoading,
    formsDomain,
    getAuth,
    getProfile,
    setUser,
    userData: userProfile,
    logoutAccount,
    updateUsername,
    readFormsDomain,
    updateFormsDomain,
    deleteAccount,
    updateEmail,
    updateEmailConfirm,
    tryEnter,
    twoFaLink,
    enable2Fa,
    disable2Fa,
    loginSilent,
  };
});

export const plugin = () => {
  OIDC.events.addUserLoaded((user) => {
    // Data Layer for Google Tag Manager (analytics)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'sign_in', userId: user.profile.sub });
  });
};
