import { library } from '@fortawesome/fontawesome-svg-core/index.js';
import svgConverter from '@fortawesome/fontawesome-pro/svg-converter';

export default async () => {
  const icons = await svgConverter([
    'download',
    'eye',
    'chevron-down',
    'clone',
    'code',
    'grip-vertical',
    'link-slash',
    'pen-to-square',
    'print',
    'plus',
    'rotate',
    'xmark',
  ], 'far');

  const duotoneIcons = await svgConverter([
    'grip-vertical',
    'trash-can-clock',
  ], 'fad');

  library.add(...icons, duotoneIcons);
};
