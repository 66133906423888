import Oidc from 'oidc-client';
import { appUrlEndpoint, authEndpoint } from '@/utils/url-manager.js';

const userStore = new Oidc.WebStorageStateStore({ store: window.localStorage });

const OIDC = new Oidc.UserManager({
  userStore,
  authority: authEndpoint,
  client_id: 'js',
  response_type: 'code',
  scope: 'openid profile web.api offline_access',
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  redirect_uri: appUrlEndpoint,
  automaticSilentRenew: true,
  silentRequestTimeout: 5000,
  post_logout_redirect_uri: `${authEndpoint}account/logout`,
});

OIDC.clearStaleState(userStore);

export {
  userStore,
  OIDC,
};
