<template>
  <aside :class="[$style['sidebar'], isSidebarOpened && $style['sidebar_opened']]">
    <div :class="$style['header']">
      <div :class="$style['header-left']">
        <product :name="getProduct" />
      </div>
      <div :class="$style['header-right']">
        <div
          :class="$style['burger']"
          @click="onBurgerClick"
        >
          <div :class="$style['burger__line']" />
          <div :class="$style['burger__line']" />
          <div :class="$style['burger__line']" />
        </div>
      </div>
    </div>
    <ul
      v-if="menu"
      :class="$style['list']"
    >
      <template v-for="item in menu">
        <li
          v-if="checkRoute(item)"
          :key="item.name"
          :class="$style['list__item']"
          :title="item.meta.sidebarName"
        >
          <router-link
            :to="{ name: item.name }"
            :class="$style['list__link']"
            :active-class="$style['list__link_active']"
            @click.native="hideList"
          >
            <div :class="$style['iconWrapper']">
              <font-awesome-icon
                :icon="['fal', item.meta.sidebarIcon]"
                :class="$style['iconWrapper__icon']"
              />
            </div>
            <span :class="$style['list__text']">
              {{ item.meta.sidebarName }}
            </span>
          </router-link>
        </li>
      </template>
    </ul>
  </aside>
</template>

<script>
import { mapState, mapActions, mapStores } from 'pinia';
import { useAppStore, useScrollStore, useTeamsStore } from '@/stores/index.js';
import product from './product.vue';

export default {
  components: { product },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSidebarOpened: false,
      isAnimated: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ['getProduct']),
    ...mapStores(useTeamsStore),
  },
  watch: {
    isSidebarOpened(value) {
      if (value) this.enableScroll('sidebar');
      else this.disableScroll('sidebar');
    },
  },
  methods: {
    ...mapActions(useScrollStore, ['enableScroll', 'disableScroll']),

    checkRoute(route) {
      return !route.meta.condition || route.meta.condition({ teams: this.teamsStore });
    },
    onBurgerClick() {
      this.isSidebarOpened = !this.isSidebarOpened;
    },
    hideList() {
      this.isSidebarOpened = false;
    },
  },
};
</script>

<style lang="scss" module>
@import '~bulma/sass/utilities/mixins';
.sidebar {
  position: fixed;
  top: 50px;
  z-index: 99;
  padding: 25px;
  width: 200px;
  height: calc(100% - 50px);
  background-color: var(--color-alabaster);

  .header {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px;
    z-index: 2;
    position: relative;
  }

  .list {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    z-index: 1;

    &__item {
      display: flex;
      align-items: center;
    }

    &__link {
      display: flex;
      padding-left: 10px;
      color: var(--color-main);
      cursor: pointer;

      &::before {
        content: '';
        position: relative;
        left: -7px;
        display: block;
        width: 5px;
        height: 25px;
        background-color: transparent;
        opacity: 0;
        transform: scale(0);
        transition: 0.1s linear;
        transition-property: transform, opacity;
      }

      &:hover,
      &_active {
        &::before {
          background-color: var(--current-color);
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .iconWrapper {
      display: none;
      font-size: 1.375rem;
      &__icon {
        object-fit: contain;
      }
    }
  }
  @include touch {
    padding: 0;
    width: 46px;
    .list {
      &__item {
        height: 40px;
      }

      &__link {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 0;
        &::before {
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          width: 3px;
        }
      }
      .iconWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 55%;
      }
      &__text {
        display: none;
      }
    }
  }

  @include mobile {
    height: auto;
    width: 100%;
    position: relative;
    .header {
      background-color: var(--color-alabaster);
      z-index: 1;
      display: flex;
      &-right {
        height: 100%;
        display: flex;
        align-items: center;
        aspect-ratio: 1;
        .burger {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          row-gap: 5px;
          cursor: pointer;

          &__line {
            transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out,
              background-color 0.35s ease-in-out;
            width: 18px;
            height: 1px;
            background-color: var(--current-color);
            &:first-child {
              transform-origin: 0 0;
            }
            &:last-child {
              transform-origin: 0 0;
            }
          }
        }
      }
    }
    .list {
      $navbar-height: 50px;
      $sidebar-height: 46px;

      height: calc(100vh - #{$navbar-height} - #{$sidebar-height});
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: var(--color-alabaster);
      transition: transform 0.35s cubic-bezier(0.19, 1, 0.56, 1);
      position: absolute;
      z-index: 0;
      row-gap: 0;
      &__item {
        border-top: 1px solid #e4e4e4;
        height: auto;
      }
      &__link {
        padding: 16px 13px 18px;
      }
      .iconWrapper {
        display: none;
      }
      &__text {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 100%;
        display: flex;
      }
    }
    &_opened {
      .list {
        transform: translateY(100%);
      }
      .burger__line {
        &:nth-child(n) {
          background-color: var(--color-main);
        }

        &:first-child {
          transform: rotate(45deg) scale(0.95);
        }

        &:nth-child(2) {
          transform: rotate(45deg);
          opacity: 0;
        }

        &:last-child {
          transform: rotate(-45deg) scale(0.95);
        }
      }
    }
  }
}
</style>
