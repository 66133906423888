<template>
<transition :name="transition" mode="in-out" appear :appear-active-class="enterClass"
            :enter-active-class="enterClass" :leave-active-class="leaveClass" @after-leave="afterLeave">
    <div :class="['notification', 'is-primary', 'animated', type ? `is-${type}` : 'is-primary']" v-if="show">
        <button class="delete touchable" @click="closedByUser()"></button>
        <div class="title is-5" v-if="title">{{ title }}</div>
        {{message}}
    </div>
</transition>
</template>

<script>
import Vue from 'vue'

export default {
    props: {
        type: String,
        title: String,
        message: String,
        direction: {
            type: String,
            default: 'Right'
        },
        duration: {
            type: Number,
            default: 4500
        },
        container: {
            type: String,
            default: '.notifications'
        }
    },

    data() {
        return {
            $_parent_: null,
            show: true
        }
    },

    created() {
        let $parent = this.$parent
        if (!$parent) {
            let parent = document.querySelector(this.container)
            if (!parent) {
                const className = this.container.replace('.', '')
                const Notifications = Vue.extend({
                    name: 'Notifications',
                    render(h) {
                        return h('div', {
                            'class': {
                                [`${className}`]: true
                            }
                        })
                    }
                })
                $parent = new Notifications().$mount()
                document.body.appendChild($parent.$el)
            } else {
                $parent = parent.__vue__
            }
            this.$_parent_ = $parent
        }
    },

    mounted() {
        if (this.$_parent_) {
            this.$_parent_.$el.appendChild(this.$el)
            this.$parent = this.$_parent_
            delete this.$_parent_
        }
        if (this.duration > 0) {
            this.timer = setTimeout(() => this.close(), this.duration)
        }
    },

    destroyed() {
        this.$el.remove()
    },

    computed: {
        transition() {
            return `bounce-${this.direction}`
        },

        enterClass() {
            return `bounceIn${this.direction}`
        },

        leaveClass() {
            return `bounceOut${this.direction}`
        },
    },

    methods: {
        closedByUser() {
            this.$emit('closed-by-user')
            clearTimeout(this.timer)
            this.show = false
        },

        close() {
            this.$emit('closed-automatically')
            clearTimeout(this.timer)
            this.show = false
        },

        afterLeave() {
            this.$destroy()
        }
    }
}
</script>

<style lang="scss">
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

.notifications {
    z-index: 10000;

    position: fixed;
    top: 64px;
    right: 0;
    pointer-events: none;

    @include tablet() {
        max-width: 320px;
    }

    .notification {
        margin: 20px;
    }
}
</style>
