import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export default defineStore('sidePanel', () => {
  const panels = ref([]);
  const currentPanel = computed(() => {
    if (!panels.value.length) return null;
    return panels.value.at(-1);
  });

  function setPanel(value) { panels.value.push(value); }
  function closePanel() { panels.value.pop(); }
  function resetPanels() { panels.value = []; }

  return {
    panels,
    currentPanel,
    setPanel,
    closePanel,
    resetPanels,
  };
});
