var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"notification","enter-class":_vm.$style.notification_enter,"enter-active-class":_vm.$style.notification_enter_active,"leave-to-class":_vm.$style.notification_leave,"leave-active-class":_vm.$style.notification_leave_active},on:{"after-leave":_vm.afterLeave}},[(_vm.show)?_c('div',{class:[
      'message is-warning',
      _vm.$style.wrapper,
    ],on:{"mouseover":_vm.clearTimer,"mouseout":() => _vm.startTimer(_vm.duration - 500)}},[_c('div',{staticClass:"message-body"},[_c('div',[_c('p',{class:[_vm.$style.text, 'has-text-grey-dark']},[_vm._v(" "+_vm._s(_vm.message)+" ")])])]),_c('button',{class:[
        'button is-ghost modal-close-btn is-shadowless has-text-grey-dark is-small',
        _vm.$style.close,
      ],attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'xmark']}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }