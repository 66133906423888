<template>
  <div :class="$style.navbar">
    <div :class="$style.menu">
      <div
        :class="[$style.overlay, isProductListOpen && $style['overlay--open']]"
        @click.stop
      >
        <div
          role="button"
          :class="$style.iconWrapper"
          @click="hideProductList"
        >
          <font-awesome-icon :icon="['fal', 'xmark']" />
        </div>
      </div>

      <b-dropdown
        ref="productListRef"
        v-model="isProductListOpen"
        :mobile-modal="false"
        :class="$style.productContainer"
        :expanded="true"
        @active-change="onProductListChange"
      >
        <template #trigger>
          <div :class="$style.trigger">
            <font-awesome-icon
              :icon="['fad', 'grip-vertical']"
              :class="$style.icon"
              flip="horizontal"
            />
          </div>
        </template>

        <div :class="$style.dropdownItems">
          <b-dropdown-item
            :class="$style.item"
            @click="onProductClick('Forms_Home')"
          >
            <Product name="forms" />
          </b-dropdown-item>

          <b-dropdown-item
            :class="$style.item"
            @click="onProductClick('DocumentsProcessesList')"
          >
            <Product name="documents" />
          </b-dropdown-item>

          <b-dropdown-item
            :class="$style.item"
            @click="onProductClick('Actions_Intro')"
          >
            <Product name="actions" />
          </b-dropdown-item>
        </div>
      </b-dropdown>

      <div :class="$style.logo">
        <Product
          :name="product"
          size="medium"
        />
      </div>
    </div>

    <TeamSelector
      v-if="!teamsSelectorHidden"
      :class="$style.team"
    />

    <div :class="$style.right">
      <HelpButton
        v-if="product === 'documents'"
        :active="helpOpen"
        @click="toggleHelp"
      />

      <div :class="[$style.settings, isSettingsOpen && $style['settings--open']]">
        <b-dropdown
          :mobile-modal="false"
          position="is-bottom-left"
          :class="$style.button"
          @active-change="onSettingsClick"
        >
          <template #trigger>
            <div :class="$style.container">
              <div
                :class="$style.trigger"
                :title="email"
              >
                <div :class="$style.background" />
                {{ userAbbr }}
              </div>
            </div>
          </template>

          <b-dropdown-item
            :custom="true"
            :class="$style.userInfo"
          >
            <div
              :title="name"
              :class="$style.name"
            >
              {{ name }}
            </div>

            <div
              v-if="name !== email"
              :title="email"
              :class="$style.email"
            >
              {{ email }}
            </div>
          </b-dropdown-item>

          <b-dropdown-item has-link>
            <router-link
              :to="{ name: 'AccountSettings' }"
              active-class=""
            >
              Account settings
            </router-link>
          </b-dropdown-item>

          <b-dropdown-item @click="logout">
            Log out
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useHelpStore, useSettingsStore, useScrollStore } from '@/stores/index.js';
import Product from '@/components/product.vue';
import HelpButton from '@/components/help/button.vue';
import TeamSelector from '@/views/settings/components/team-selector.vue';

export default {
  components: {
    Product,
    TeamSelector,
    HelpButton,
  },
  props: {
    product: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSettingsOpen: false,
      isProductListOpen: false,
    };
  },
  computed: {
    ...mapState(useHelpStore, { helpOpen: 'open' }),
    ...mapState(useSettingsStore, ['getProfile']),
    teamsSelectorHidden() {
      return ['TeamsSettings', 'AccountSettings'].includes(this.$route.name);
    },
    email() {
      return this.getProfile?.email;
    },
    name() {
      return this.getProfile?.name;
    },
    userAbbr() {
      if (this.name) {
        const splitName = this.name?.split(' ');

        if (splitName.length > 1) return `${splitName[0][0]}${splitName[1][0]}`;

        return splitName[0][0];
      }

      return '';
    },
  },
  watch: {
    isProductListOpen(value) {
      if (window.innerWidth < 768) {
        if (value) this.enableScroll('navbar');
        else this.disableScroll('navbar');
      }
    },
  },
  methods: {
    ...mapActions(useHelpStore, { toggleHelp: 'toggleOpen' }),
    ...mapActions(useScrollStore, ['enableScroll', 'disableScroll']),
    ...mapActions(useSettingsStore, ['logoutAccount']),

    logout() {
      return this.logoutAccount();
    },
    onSettingsClick(value) {
      return (this.isSettingsOpen = value);
    },
    onProductListChange(e) {
      this.isProductListOpen = e;
    },
    hideProductList() {
      this.$refs.productListRef.toggle();
    },
    onProductClick(name) {
      if (name !== this.$route.name) {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style lang="scss" module>
@import '~bulma/sass/utilities/mixins';

.navbar {
  position: fixed;
  z-index: 998;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--color-light);
  width: 100%;
  height: 50px;
  background-color: var(--color-white);

  .overlay {
    display: none;
  }

  .menu {
    display: flex;

    .trigger {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 47px;
      height: 100%;
      background-color: var(--current-color);
      transition: background-color linear;
      transition-duration: var(--transition-duration);
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-black);
        opacity: 0;
        transition: opacity linear;
        transition-duration: var(--transition-duration);
        pointer-events: none;
      }

      .container {
        width: 100%;
        height: 100%;
      }

      .icon {
        width: 15px;
        height: 21px;
        color: var(--color-white);
      }

      &:hover::before {
        opacity: 0.2;
      }
    }

    .logo {
      display: flex;
      align-items: center;
      margin-left: 15px;
      width: 120px;

      @include mobile {
        display: none;
      }
    }

    :global(.dropdown) {
      transition: background-color 0.25s ease-in-out;

      path {
        transition: fill 0.25s ease-in-out, opacity 0.25s ease-in-out;
      }
    }

    :global(.dropdown.is-active) {
      .trigger {
        background-color: transparent;

        path {
          fill: var(--current-color);

          &:first-child {
            opacity: 0.4;
          }
        }
      }
    }

    .productContainer {
      width: auto;

      :global(.dropdown-menu) {
        padding-top: 2px;
        width: auto;
      }

      .dropdownItems {
        .item {
          display: flex;
          border-top: 1px solid #e4e4e4;
          padding: 11px 3rem 11px 10px;

          &:first-child {
            border-top: none;
          }
        }
      }

      @include mobile {
        position: initial;
        z-index: 2;

        :global(.dropdown-menu) {
          width: 100%;
        }

        .dropdownItems {
          height: calc(100vh - 48px);
        }
      }
    }
  }

  .settings {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    transition: background-color linear;
    transition-duration: var(--transition-duration);
    cursor: pointer;

    .trigger {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-white);
      border-radius: 50%;
      width: 36px;
      height: 36px;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      color: var(--color-white);

      .background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 50%;
        background-color: var(--current-color);
        transition: background-color linear;
        transition-duration: var(--transition-duration);
      }
    }

    :global(.dropdown) {
      :global(.dropdown-menu) {
        right: -7px;
        padding-top: 2px;
        width: 200px;
      }
    }

    .userInfo {
      color: var(--color-main);
      background-color: var(--color-gray-light);
      cursor: initial;

      .name {
        font-weight: 600;
        font-size: 16px;
      }

      .email {
        font-size: 12px;
      }

      .name,
      .email {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .button,
    :global(.dropdown-trigger) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &:hover,
    &--open {
      background-color: var(--current-color);
    }
  }

  .right {
    display: flex;
  }

  .team {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    transform: translateX(-50%);
  }

  @include mobile {
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      background-color: var(--color-white);
      opacity: 0;
      transition: opacity 0.15s ease-in-out, z-index 0s ease-in-out 0.15s;

      .iconWrapper {
        display: flex;
        align-items: center;
        padding: 0 15px;
        height: 100%;
        cursor: pointer;
        font-size: 1.4rem;
      }

      &--open {
        z-index: 1;
        opacity: 1;
        transition: opacity 0.15s ease-in-out, z-index 0s ease-in-out;
      }
    }

    .team {
      position: relative;
      left: auto;
      transform: none;
    }
  }
}
</style>
