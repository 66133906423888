import axios from 'axios';
import { defineStore } from 'pinia';
import { apiEndpoint } from '@/utils/url-manager.js';

const api = apiEndpoint;

export default defineStore('reports', {
  state: () => ({
    chart: null,
    table: null,
    tableContinuationToken: null,
  }),

  actions: {
    async readChart({ product, from, to }) {
      try {
        const { data } = await axios.get(
          `${api}${product}/reports/data-chart`,
          { params: { From: from, To: to } },
        );

        this.chart = data;

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },
    async readTable({
      product,
      from,
      to,
      continuationToken = null,
    }) {
      try {
        const { data } = await axios.get(
          `${api}${product}/reports/data-table`,
          { params: { From: from, To: to, ContinuationToken: continuationToken } },
        );

        if (continuationToken) {
          this.table = [...this.table, ...data.data];
        } else {
          this.table = data.data;
        }

        this.tableContinuationToken = data.continuationToken;

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },
    async readCsv({ product, from, to }) {
      try {
        const { data } = await axios.get(
          `${api}${product}/reports/export-to-csv`,
          { params: { From: from, To: to } },
        );

        window.open(`${api}${product}/reports/export-to-csv/${data.key}`);

        return { data, error: null };
      } catch (error) {
        return { data: null, error: error.response.data };
      }
    },
  },
});
