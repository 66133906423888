export default (store, pinia) => async (to, from, next) => {
  const settings = store(pinia);

  const { user } = await settings.tryEnter();

  if (!user || !settings.getAuth) {
    // clean related cookies
    settings.logoutAccount();
  }

  return next();
};
