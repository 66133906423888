export default {
  name: 'Documents',
  path: '/documents',
  redirect: {
    name: 'DocumentsProcesses',
  },
  meta: {
    product: 'documents',
  },
  component: () => import('@/views/documents/index.vue'),
  children: [
    {
      name: 'DocumentsProcesses',
      path: 'processes',
      redirect: {
        name: 'DocumentsProcessesList',
      },
      meta: {
        sidebarName: 'Processes',
        sidebarIcon: 'gear',
      },
      component: {
        render: (c) => c('router-view'),
      },
      children: [
        {
          name: 'DocumentsProcessesList',
          path: '',
          alias: ['/documents/intro/reg', '/documents/processes/reg'],
          meta: {
            title: 'Processes',
          },
          component: () => import('@/views/documents/processes/processes-list.vue'),
        },
        {
          name: 'DocumentsProcessCreate',
          path: 'create',
          meta: {
            title: 'Create Process',
          },
          component: () => import('@/views/documents/processes/process-create.vue'),
        },
        {
          name: 'DocumentsTemplatesList',
          path: 'select-template',
          meta: {
            title: 'Select template',
          },
          component: () => import('@/views/documents/processes/templates-list.vue'),
        },
        {
          name: 'DocumentsProcessCreateFromTemplate',
          path: 'create-from-template/:slug',
          meta: {
            titleComponent: () => import('@/views/documents/processes/components/process-create-title.vue'),
          },
          component: () => import('@/views/documents/processes/process-create-from-template.vue'),
        },
        {
          name: 'DocumentsProcess',
          path: ':processId',
          redirect: {
            name: 'DocumentsProcessSummary',
          },
          props: true,
          meta: {},
          component: () => import('@/views/documents/processes/process.vue'),
          children: [
            {
              name: 'DocumentsProcessSummary',
              path: '',
              props: true,
              meta: {
                titleComponent: () => import('@/views/documents/processes/components/process-title.vue'),
              },
              component: () => import('@/views/documents/processes/process-summary.vue'),
            },
            {
              name: 'DocumentsProcessJob',
              path: 'jobs/:jobId',
              props: true,
              meta: {
                titleComponent: () => import('@/views/documents/processes/components/history-title.vue'),
              },
              component: () => import('@/views/documents/processes/process-job.vue'),
            },
            {
              name: 'DocumentsProcessEdit',
              path: 'edit',
              props: true,
              meta: {
                titleComponent: () => import('@/views/documents/processes/components/process-title.vue'),
                titleEditable: true,
                contentClass: 'content--documents-editor',
              },
              component: () => import('@/views/documents/processes/process-settings.vue'),
            },
          ],
        },
      ],
    },
    {
      name: 'DocumentsAPIKeys',
      path: 'api-keys',
      meta: {
        title: 'API keys',
        sidebarName: 'API keys',
        sidebarIcon: 'key-skeleton-left-right',
      },
      component: () => import('@/views/documents/apiKeys/index.vue'),
    },
    {
      name: 'DocumentsReports',
      path: 'reports',
      meta: {
        title: 'Reports',
        sidebarName: 'Reports',
        sidebarIcon: 'chart-mixed',
      },
      component: () => import('@/views/common/reports/index.vue'),
    },
    {
      name: 'DocumentsProcessesRecycleBin',
      path: 'recycle-bin',
      meta: {
        title: 'Recycle bin',
        sidebarName: 'Recycle bin',
        sidebarIcon: 'trash-can-clock',
      },
      component: () => import('@/views/documents/recycleBin/recycle-bin-list.vue'),
    },
    {
      name: 'DocumentsSubscriptions',
      path: 'subscription',
      meta: {
        titleComponent: () => import('@/views/common/subscriptions/components/subscriptions-title.vue'),
        sidebarName: 'Subscription',
        sidebarIcon: 'circle-dollar-to-slot',
        indexClass: 'documents--subscription',
      },
      component: () => import('@/views/common/subscriptions/index.vue'),
    },
  ],
};
