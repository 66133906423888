export default {
  name: 'Forms',
  path: '/forms',
  redirect: '/forms/home',
  meta: {
    // TODO: what is expanded, remove it?
    expanded: true,
    product: 'forms',
  },
  component: () => import('@/views/forms/index.vue'),
  children: [
    {
      name: 'Forms_Home',
      path: 'home',
      meta: {
        title: 'Welcome to Plumsail Forms',
        sidebarName: 'Home',
        breadcrumb: '',
        sidebarIcon: 'house',
        contentClass: 'app-content-forms-home',
      },
      component: () => import('@/views/forms/home.vue'),
    },
    {
      name: 'Forms_Forms',
      path: 'forms',
      meta: {
        title: 'Forms',
        sidebarName: 'Forms',
        breadcrumb: 'Forms',
        sidebarIcon: 'bars-staggered',
      },
      component: () => import('@/views/forms/forms-page.vue'),
      children: [
        {
          name: 'Forms_Forms_Submissions',
          path: ':formId',
          props: true,
          meta: {
            title: ' ',
            breadcrumb: {
              lazy: true,
            },
            contentClass: 'app-content-forms-submissions',
            levelbarClass: 'levelbar-forms-submissions',
          },
          component: () => import('@/views/forms/submissions/submissions-page.vue'),
          children: [
            {
              name: 'Forms_Forms_Submissions_View',
              path: ':submissionId',
              props: true,
              meta: {
                title: ' ',
                breadcrumb: ' ',
              },
              component: () => import('@/views/forms/submissions/submission-view-page.vue'),
            },
          ],
        },
      ],
    },
    {
      name: 'Forms_Pages',
      path: 'pages',
      meta: {
        title: 'Pages',
        sidebarName: 'Pages',
        breadcrumb: 'Pages',
        sidebarIcon: 'page',
      },
      component: () => import('@/views/forms/pages/pages-page.vue'),
      children: [
        {
          name: 'Forms_Pages_Editor',
          path: ':pageId',
          props: true,
          meta: {
            title: ' ',
            breadcrumb: {
              lazy: true,
            },
          },
          component: () => import('@/views/forms/pages/pages-editor.vue'),
        },
      ],
    },
    {
      name: 'Forms_Reports',
      path: 'reports',
      meta: {
        title: 'Reports',
        sidebarName: 'Reports',
        breadcrumb: 'Reports',
        sidebarIcon: 'chart-mixed',
      },
      component: () => import('@/views/forms/reports.vue'),
    },
    {
      name: 'Forms_Subscriptions',
      path: 'subscriptions',
      meta: {
        title: 'Subscriptions',
        sidebarName: 'Subscriptions',
        breadcrumb: '',
        sidebarIcon: 'circle-dollar-to-slot',
        condition: (store) => store.teams.getOwnTeamSelected,
      },
      component: () => import('@/views/forms/subscriptions.vue'),
    },
  ],
};
