import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';

enum CloseEvents {
    mousedown = 'mousedown',
    keydown = 'keydown'
}

type EventTypes = KeyboardEvent | MouseEvent;

type ElementCustomHandlers = {
    [key in CloseEvents]: (event: EventTypes) => void;
};

type Element = HTMLElement & ElementCustomHandlers;

type ListenersConfig = {
    [key in CloseEvents]: {
        handler: (event: EventTypes, el: Element, binding: DirectiveBinding) => void;
        eventType: CloseEvents;
    };
};

const listeners: ListenersConfig = {
  mousedown: {
    eventType: CloseEvents.mousedown,
    handler: (event, el, binding) => {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value();
      }
    },
  },
  keydown: {
    eventType: CloseEvents.keydown,
    handler: (event, _, binding) => {
      if (event instanceof KeyboardEvent && event.key === 'Escape') {
        binding.value();
      }
    },
  },
};

export default Vue.directive('close-popup', {
  bind(el, binding) {
    Object.values(listeners).forEach((listener) => {
      const { eventType, handler } = listener;
      (el as Element)[eventType] = (event) => {
        handler(event, el as Element, binding);
      };
      document.body.addEventListener(eventType, (el as Element)[eventType]);
    });
  },
  unbind(el) {
    Object.values(listeners).forEach((listener) => {
      const { eventType } = listener;
      document.body.removeEventListener(eventType, (el as Element)[eventType]);
    });
  },
});
